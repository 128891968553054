// userRegisterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step1Data: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  },
  step2Data: {
    cameras: [{ cameraName: '', subscriptionPlan: '', addOnPlan: '' }],

    discount : '',
    isDiscountApplied : false,
    discountResponseMsg: '',
    discountResponse :{},

    country: '',
    state: '',
    address: '',
    city: '',
    zip: '',

    cardHolder: '',
    cardNumber: '',
    expiry_month: '',
    expiry_year :'',
    cvv: '',

    totalBill: 0,
    isUnitedStateSelected : false,
    
  },
};

const userRegisterSlice = createSlice({
  name: 'userRegister',
  initialState: initialState,
  reducers: {
    setUserRegisterDataStep1: (state, action) => {
      const { first_name, last_name, email, phone, password, confirmPassword } = action.payload;
      state.first_name = first_name;
      state.last_name = last_name;
      state.email = email;
      state.phone = phone;
      state.password = password;
      state.confirmPassword = confirmPassword;
    },
    setUserRegisterDataStep2: (state_data, action) => {
      const { cameras, discount,isDiscountApplied, discountResponseMsg, discountResponse, country, state, address, city, zip, cardHolder, cardNumber, expiry_month, expiry_year, cvv, totalBill, isUnitedStateSelected } = action.payload;
      state_data.cameras = cameras;

      state_data.discount = discount;
      state_data.isDiscountApplied = isDiscountApplied;
      state_data.discountResponseMsg = discountResponseMsg;
      state_data.discountResponse = discountResponse;
      
      state_data.country = country;
      state_data.state = state;
      state_data.address = address;
      state_data.city = city;
      state_data.zip = zip;

      state_data.cardHolder = cardHolder;
      state_data.cardNumber = cardNumber;
      state_data.expiry_month = expiry_month;
      state_data.expiry_year = expiry_year;
      state_data.cvv = cvv;

      state_data.totalBill = totalBill;
      state_data.isUnitedStateSelected = isUnitedStateSelected
    },
    resetUserRegister: (state) => {
      return initialState;
    },
  },
});

export const { setUserRegisterDataStep1, setUserRegisterDataStep2, resetUserRegister } = userRegisterSlice.actions;

export default userRegisterSlice.reducer;
