// RegisterStep1.js
import '../register/RegisterStep1.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import CustomLoader from '../../components/utils/CustomLoader';
import FixedHeader from '../../components/fixed-header/FixedHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contactUs } from '../../api/common/CommonApi';

const ContactUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
   const { addToast } = useToasts();
  const transitionRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    full_name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object({
    full_name: Yup.string().required('First Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });

  const onSubmit = async (values) => {

    const request_data = {
      name: values.full_name,
      email: values.email,
      phone: values.phone,
      subject: values.subject,
      message: values.message,
    };

    console.log("request_data ", request_data);
    setLoading(true);

    try {
            const response = await contactUs(request_data);
            setLoading(false);
            if(response.status_code == 200)
            {
                addToast(response.message, { appearance: 'success' });
            }
            else{
                addToast(response.message, { appearance: 'error' });
            }
            
        } catch (error) {
            setLoading(false);
        // Handle error
        if (error.status_code === 404) {
            // Handle 404 Not Found error
            } else if (error.status_code === 500) {
            // Handle 500 Internal Server Error
            } else {
            // Handle other error cases
            }
        }

    
  };

  useEffect(() => {

  }, []);

 

  return (
    <>

        {loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}

      <div>
        <FixedHeader/>
        <section className="inner-page-title">
          <div className="container">
            <div className="row">
              <h1 className="text-center text-white">Contact Us</h1>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            {/* <div className="row">
              <h2 className="text-primary text-center mb-5">Create your account</h2>
            </div> */}
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                  <>

                    <Form>
                      
                      <div className="row register-tab">


                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            {/* <h4 className="text-center mb-3">Personal Details</h4> */}
                            <div className="form-main row">
                              <div className="col-md-12">
                                <label>Full Name <sup>*</sup></label>
                                <Field type="text" name="full_name" className="form-control" /><span className="form-icon"><i className="fa fa-user" /></span>
                                <ErrorMessage name="full_name" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="form-main row">
                              <div className="col-md-6">
                                <label>EMAIL <sup>*</sup></label>
                                <Field type="email" name="email" className="form-control" /><span className="form-icon"><i className="fa fa-envelope" /></span>
                                <ErrorMessage name="email" component="div" className="text-danger" />
                              </div>

                              <div className="col-md-6">
                                <label>Phone Number <sup>*</sup></label>
                                <Field type="text" name="phone" className="form-control" /><span className="form-icon"><i className="fa fa-phone" /></span>
                                <ErrorMessage name="phone" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="form-main row">
                              <div className="col-md-12">
                                <label>SUBJECT <sup>*</sup></label>
                                <Field type="text" name="subject" className="form-control" />
                                <ErrorMessage name="subject" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="form-main row">
                              <div className="col-md-12">
                                <label>MESSAGE <sup>*</sup></label>
                                <Field style={{height:"150px", resize: "none"}} as="textarea" name="message" className="form-control" />
                                <ErrorMessage name="message" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="text-center">
                              <button type="submit" className="btn btn-lg btn-primary">Send Message</button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </Form>

                  </>
            )}
            </Formik>
            
            {/* <div className="text-center">Already have an account? <Link to="/login">Log in</Link></div> */}
          </div>
        </section>
        <footer>
          Copyright © 2023 IPCamStream
          <footer>
          </footer></footer></div>
    </>
  );
};

export default ContactUs;
