// userLoginSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userLoginResponse: {},
};

const userLoginSlice = createSlice({
  name: 'userLogin',
  initialState: initialState,
  reducers: {
    setUserLoginResponse: (state, action) => {
      const { userLoginResponse} = action.payload;
      state.userLoginResponse = userLoginResponse;
    },
    resetUserLogin: (state) => {
      return initialState;
    },
  },
});

export const { setUserLoginResponse, resetUserLogin } = userLoginSlice.actions;

export default userLoginSlice.reducer;
