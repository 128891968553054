import React from 'react'

function FixedFooter() {
  return (
    <>
        <footer>
            <p>2023 IPCamStream. All rights reserved.</p>
        </footer>
    </>
  )
}

export default FixedFooter