import cameraDetailSlice from './slices/cameraDetailSlice';
import { combineReducers } from 'redux';
import counterSliceData from './slices/counterSliceData';
import userLoginSlice from './slices/userLoginSlice';
import userRegisterReducer from './slices/userRegisterSlice';

// Combine the reducers
const rootReducer = combineReducers({
  counter: counterSliceData.reducer,
  userRegister: userRegisterReducer,
  userLogin: userLoginSlice,
  cameraDetails: cameraDetailSlice
});

export default rootReducer;
