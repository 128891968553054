import './RegisterStep2.css';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {
	checkDiscount,
	getAddOnPlans,
	getCountries,
	getStates,
	getSubscriptionPlans,
	userRegister,
} from '../../api/common/CommonApi';
import {
	faEye,
	faEyeSlash,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
	setUserRegisterDataStep1,
	setUserRegisterDataStep2,
} from '../../redux/slices/userRegisterSlice';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from 'react-avatar';
import { CSSTransition } from 'react-transition-group';
import Cards from 'react-credit-cards-2';
import CustomLoader from '../../components/utils/CustomLoader';
import FixedHeader from '../../components/fixed-header/FixedHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import { produce } from 'immer';

const RegisterStep2 = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const { addToast } = useToasts();
	const userRegisterData = useSelector((state) => state.userRegister);
	const transitionRef = useRef(null);
	const formikRef = useRef();

	const [state, setState] = useState({
		number: '',
		expiry: '',
		cvc: '',
		name: '',
		focus: '',
	});

	const [loading, setLoading] = useState(false);

	const [showCVV, setShowCVV] = useState(false);
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const [addOnPlans, setAddOnPlans] = useState([]);
	const [countriesList, setCountriesList] = useState([]);
	const [statesList, setStatesList] = useState([]);
	const [isUnitedStateSelected, setIsUnitedStateSelected] = useState(
		userRegisterData.isUnitedStateSelected
	);
	const [totalBill, setTotalBill] = useState(
		userRegisterData.totalBill ? userRegisterData.totalBill : 0
	);

	const [isDiscountApplied, setIsDiscountApplied] = useState(
		userRegisterData.isDiscountApplied
	);
	const [discountResponseMsg, setDiscountResponseMsg] = useState(
		userRegisterData.discountResponseMsg
	);
	const [discountResponse, setDiscountResponse] = useState(
		userRegisterData.discountResponse ? userRegisterData.discountResponse : {}
	);

	const initialValues = {
		cameras: userRegisterData.cameras || [
			{ cameraName: '', subscriptionPlan: '', addOnPlan: '' },
		],

		discount: userRegisterData.discount,

		country: userRegisterData.country,
		state: userRegisterData.state,
		address: userRegisterData.address,
		city: userRegisterData.city,
		zip: userRegisterData.zip,

		cardHolder: userRegisterData.cardHolder,
		cardNumber: userRegisterData.cardNumber,
		expiry_month: userRegisterData.expiry_month,
		expiry_year: userRegisterData.expiry_year,
		cvv: userRegisterData.cvv,

		termsAndConditions: false,
	};

	const validationSchema = Yup.object({
		cameras: Yup.array().of(
			Yup.object().shape({
				cameraName: Yup.string().required('Camera Name is required'),
				subscriptionPlan: Yup.string().required(
					'Subscription Plan is required'
				),
				addOnPlan: Yup.string(),
			})
		),

		discount: Yup.string(),

		country: Yup.string().required('Country is required'),
		state: Yup.string().required('State is required'),
		address: Yup.string().required('Address is required'),
		city: Yup.string().required('City is required'),
		zip: Yup.string().required('Zip is required'),

		cardHolder: Yup.string().required('Card Holder is required'),
		cardNumber: Yup.string()
			.required('Card Number is required')
			.matches(/^[0-9]+$/, 'Card Number must contain only numeric digits')
			.max(19, 'Card Number must not exceed 19 digits'),
		expiry_month: Yup.string().required('Expiration Month is required'),
		expiry_year: Yup.string().required('Expiration Year is required'),
		cvv: Yup.string()
			.required('CVV is required')
			.max(4, 'CVV must be at most 4 digits')
			.matches(/^[0-9]+$/, 'CVV must contain only numeric digits'),

		termsAndConditions: Yup.boolean()
			.oneOf([true], 'You must accept the terms and conditions')
			.required('You must accept the terms and conditions'),
	});

	const onSubmit = async (values) => {
		// Handle form submission logic here

		console.log('submit ', values);
		setLoading(true);

		// Simulate an asynchronous operation
		// await new Promise((resolve) => setTimeout(resolve, 5000));

		const userRegisterDataStore = {
			cameras: values.cameras,

			discount: values.discount,
			isDiscountApplied: isDiscountApplied,
			discountResponseMsg: discountResponseMsg,

			country: values.country,
			state: values.state,
			address: values.address,
			city: values.city,
			zip: values.zip,

			cardHolder: values.cardHolder,
			cardNumber: values.cardNumber,
			expiry_month: values.expiry_month,
			expiry_year: values.expiry_year,
			cvv: values.cvv,

			totalBill: totalBill,
			isUnitedStateSelected: isUnitedStateSelected,
		};

		dispatch(setUserRegisterDataStep2(userRegisterDataStore));

		const country = countriesList.find((country) => {
			if (country.id == values.country) {
				return country;
			}
		});

		console.log('country_name ', country.country_name);
		const country_name = country.country_name;

		const updatedCameras = values.cameras.map((camera) => {
			const { cameraName, subscriptionPlan, addOnPlan, ...rest } = camera;
			return {
				device_name: cameraName,
				service_plan_id: subscriptionPlan,
				add_on: addOnPlan,
				...rest,
			};
		});

		console.log(updatedCameras);

		let request_data = {};
		request_data.email = userRegisterData.email;
		request_data.password = userRegisterData.password;
		request_data.first_name = userRegisterData.first_name;
		request_data.last_name = userRegisterData.last_name;
		request_data.phone = userRegisterData.phone;
		request_data.address = values.address;
		request_data.city = values.city;
		request_data.state = values.state;
		request_data.zip = values.zip;
		request_data.country = country_name;
		request_data.devices = updatedCameras;
		request_data.discount = values.discount;
		request_data.credit_card = {
			cardholder_name: values.cardHolder,
			cvv: values.cvv,
			expiration_date: values.expiry_month + '/' + values.expiry_year,
			number: values.cardNumber,
		};

		console.log(request_data);

		try {
			const response = await userRegister(request_data);
			console.log('response register ', response);
			setLoading(false);
			if (response.status_code == 200) {
				console.log('response', response.message);
				addToast(response.message, { appearance: 'success' });

				dispatch(setUserRegisterDataStep2({}));
				dispatch(setUserRegisterDataStep1({}));
				navigate('/login');
			} else if (response.status_code == 400) {
				console.log('response', response.message);
				addToast(response.message, { appearance: 'error' });
			}

			// setStatesList(checkedDiscount.states);
		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	const toggleCVVVisibility = () => {
		setShowCVV(!showCVV);
	};

	const goToBack = (values) => {
		console.log('goToBack');

		const updatedValues = { ...values };

		console.log('discountResponse ', discountResponse);

		const userRegisterDataStore = {
			cameras: updatedValues.cameras,
			discount: updatedValues.discount,
			isDiscountApplied: isDiscountApplied,
			discountResponseMsg: discountResponseMsg,
			discountResponse: discountResponse,
			country: updatedValues.country,
			state: updatedValues.state,
			address: updatedValues.address,
			city: updatedValues.city,
			zip: updatedValues.zip,
			cardHolder: updatedValues.cardHolder,
			cardNumber: updatedValues.cardNumber,
			expiry_month: updatedValues.expiry_month,
			expiry_year: updatedValues.expiry_year,
			cvv: updatedValues.cvv,
			totalBill: totalBill,
			isUnitedStateSelected: isUnitedStateSelected,
		};

		dispatch(setUserRegisterDataStep2(userRegisterDataStore));

		console.log(updatedValues);

		navigate('/register-step1');
	};

	useEffect(() => {
		const profileTabButton = document.getElementById('profile-tab');
		profileTabButton.click();

		initialValues.cameras = userRegisterData.cameras || [
			{ cameraName: '', subscriptionPlan: '', addOnPlan: '' },
		];

		initialValues.discount = userRegisterData.discount;

		initialValues.country = userRegisterData.country;
		initialValues.state = userRegisterData.state;
		initialValues.address = userRegisterData.address;
		initialValues.city = userRegisterData.city;
		initialValues.zip = userRegisterData.zip;

		initialValues.cardHolder = userRegisterData.cardHolder;
		initialValues.cardNumber = userRegisterData.cardNumber;
		initialValues.expiry_month = userRegisterData.expiry_month;
		initialValues.expiry_year = userRegisterData.expiry_year;
		initialValues.cvv = userRegisterData.cvv;

		const fetchData = async () => {
			try {
				console.log('calling');
				const [
					subscriptionPlansResponse,
					addOnPlansResponse,
					countriesResponse,
					statesResponse,
				] = await Promise.all([
					getSubscriptionPlansApi(),
					fetchAddOnPlans(),
					fetchCountries(),
					fetchStates(),
				]);
			} catch (error) {
				// Handle error
			}
		};

		fetchData();

		//  getSubscriptionPlansApi();
		//  fetchAddOnPlans();
		// initializeTotalPrice(initialValues.cameras);
	}, []);

	const getSubscriptionPlansApi = async () => {
		try {
			const subscription_plans = await getSubscriptionPlans();

			setSubscriptionPlans(subscription_plans.plans);
		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	const fetchAddOnPlans = async () => {
		// Make API call to fetch add-on plans
		// Assuming it returns an array of add-on plans

		try {
			const fetchedAddOnPlans = await getAddOnPlans();
			// console.log(fetchedAddOnPlans.plans);
			setAddOnPlans(fetchedAddOnPlans.plans);
		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	const fetchCountries = async () => {
		try {
			const fetchedCountries = await getCountries();
			setCountriesList(fetchedCountries.countries);
		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	const fetchStates = async () => {
		try {
			const fetchedStates = await getStates();
			setStatesList(fetchedStates.states);
		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	const handleSubscriptionPlanChange = (
		event,
		index,
		setFieldValue,
		values
	) => {
		const selectedValue = event.target.value;
		console.log('Selected Subscription Plan:', selectedValue);

		const updatedCameras = produce(values.cameras, (draftCameras) => {
			draftCameras[index].subscriptionPlan = selectedValue;
		});

		setFieldValue(`cameras[${index}].subscriptionPlan`, selectedValue);
		setFieldValue('cameras', updatedCameras);

		console.log('discountResponse ', discountResponse);

		setTimeout(() => {
			calculateTotalBillOnChange(
				formikRef.current.values,
				discountResponse.coupon_total_amount
			);
		}, 100);
	};

	const handleAddOnPlanChange = (event, index, setFieldValue, values) => {
		const selectedValue = event.target.value;
		console.log('Selected Add-On Plan:', selectedValue);

		const updatedCameras = produce(values.cameras, (draftCameras) => {
			draftCameras[index].addOnPlan = selectedValue;
		});

		setFieldValue(`cameras[${index}].addOnPlan`, selectedValue);
		setFieldValue('cameras', updatedCameras);

		setTimeout(() => {
			calculateTotalBillOnChange(
				formikRef.current.values,
				discountResponse.coupon_total_amount
			);
		}, 100);

		//   console.log('Updated Values:', values.cameras);

		//   let temp_subs_plan_total = 0;
		//   const temp_cameras = values.cameras;

		//   temp_cameras.forEach((camera) => {
		//     const subscriptionPlan = subscriptionPlans.find(
		//       (plan) => plan.subscription_plan_id === camera.subscriptionPlan
		//     );

		//     if (subscriptionPlan) {
		//       temp_subs_plan_total += subscriptionPlan.price;
		//       console.log(
		//         `Camera: ${camera.cameraName}, Subscription Plan: ${camera.subscriptionPlan}, Price: ${subscriptionPlan.price}`
		//       );
		//     }
		//   });

		//   let totalPrice = temp_subs_plan_total;
		//   console.log("updatedCameras ", updatedCameras)
		//   const cameras = updatedCameras;

		//   cameras.forEach((camera, index) => {
		//     const addOnPlan = addOnPlans.find((addon) => addon.addon_id === camera.addOnPlan);

		//     if (addOnPlan) {
		//       console.log('Add-On Plan:', addOnPlan);
		//       totalPrice += addOnPlan.addon_total_amount;
		//     }

		//     if(cameras.length-1 == index){
		//         setTotalBill(totalPrice);
		//     }

		//   });
	};

	const handleCountryChange = (event, setFieldValue) => {
		const selectedValue = event.target.value;
		console.log('Selected Country:', selectedValue);

		setFieldValue('country', selectedValue);

		if (selectedValue == 250) {
			setIsUnitedStateSelected(true);
		} else {
			setIsUnitedStateSelected(false);
		}
	};

	const handleStateChange = (event, setFieldValue) => {
		const selectedValue = event.target.value;
		setFieldValue('state', selectedValue);
	};

	const handleExpiryMonthChange = (event, setFieldValue) => {
		const selectedValue = event.target.value;
		setFieldValue('expiry_month', selectedValue);
	};

	const handleExpiryYearChange = (event, setFieldValue) => {
		const selectedValue = event.target.value;
		setFieldValue('expiry_year', selectedValue);
	};

	const handleCardNumberChange = (event, setFieldValue) => {
		const cardNumber = event.target.value.replace(/ /g, '');
		setFieldValue('cardNumber', cardNumber);
	};

	const formatCardNumber = (cardNumber) => {
		if (!cardNumber) return '';
		const formattedNumber = cardNumber
			.replace(/\s/g, '')
			.replace(/(\d{4})/g, '$1 ');
		return formattedNumber.trim();
	};

	const removeCouponCode = (values) => {
		setIsDiscountApplied(false);
		setDiscountResponseMsg('');
		setDiscountResponse({});

		console.log(formikRef.current.values);
		setTimeout(() => {
			const updatedValues = {
				...formikRef.current.values,
				isDiscountApplied: false,
				discountResponseMsg: '',
			};
			dispatch(setUserRegisterDataStep2(updatedValues));

			calculateTotalBill(formikRef.current.values, 100, false);
		}, 100);
	};

	const applyCouponCode = async (values) => {
		// API call for discount

		const discount = values.discount;

		let request_data = {
			coupon_id: discount,
		};

		setLoading(true);

		try {
			const response = await checkDiscount(request_data);
			console.log('checkedDiscount ', response);
			setLoading(false);
			if (response.status_code == 200) {
				setIsDiscountApplied(true);
				setDiscountResponseMsg(response.message);
				setDiscountResponse(response.Discount);

				setTimeout(async () => {
					const updatedValues = {
						...formikRef.current.values,
						isDiscountApplied: true,
						discountResponseMsg: response.message,
					};
					await dispatch(setUserRegisterDataStep2(updatedValues));

					calculateTotalBill(
						formikRef.current.values,
						response.Discount.coupon_total_amount,
						true
					);
				}, 100);
			} else if (response.status_code == 400) {
				setIsDiscountApplied(false);
				setDiscountResponseMsg(response.message);
			}

			// setStatesList(checkedDiscount.states);
		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	const calculateTotalBillOnChange = async (values, discount_percentage) => {
		let cameras = [...values.cameras];

		console.log('values ', values.cameras);

		let temp_subs_plan_total = 0;
		const temp_cameras = values.cameras;

		let per_device_discount = [];

		await temp_cameras.forEach((camera) => {
			const subscriptionPlan = subscriptionPlans.find(
				(plan) =>
					plan.subscription_plan_id === camera.subscriptionPlan &&
					plan.is_free == false
			);

			if (subscriptionPlan) {
				temp_subs_plan_total += subscriptionPlan.price;

				const abc = (
					(subscriptionPlan.price * discount_percentage) /
					100
				).toFixed(2);
				per_device_discount.push(abc);

				console.log(
					`Camera: ${camera.cameraName}, Subscription Plan: ${camera.subscriptionPlan}, Price: ${subscriptionPlan.price}`
				);
			}
		});

		let totalPrice = 0;
		console.log('isDiscountApplied ', isDiscountApplied);
		if (isDiscountApplied) {
			// adding all value from array
			const total_price = per_device_discount.reduce(
				(accumulator, currentValue) => accumulator + parseFloat(currentValue),
				0
			);
			totalPrice = temp_subs_plan_total - total_price;
		} else {
			totalPrice = temp_subs_plan_total;
		}

		console.log('totalPrice ', totalPrice);

		await cameras.forEach((camera, index) => {
			const addOnPlan = addOnPlans.find(
				(addon) => addon.addon_id === camera.addOnPlan
			);
			// console.log("addOnPlan obj ", addOnPlan);

			if (addOnPlan) {
				console.log('Add-On Plan:', addOnPlan, camera.subscriptionPlan);
				if (camera.subscriptionPlan == 'ipcam_standard_free_trial') {
					const dis = (addOnPlan.addon_total_amount * 23.33) / 100;
					totalPrice += addOnPlan.addon_total_amount + dis;
				} else {
					totalPrice += addOnPlan.addon_total_amount;
				}
			}

			if (cameras.length - 1 == index) {
				setTotalBill(totalPrice);
			}
		});

		setTotalBill(totalPrice.toFixed(2));
	};

	const calculateTotalBill = async (
		values,
		discount_percentage,
		is_coupon_applied
	) => {
		let cameras = [...values.cameras];

		console.log('values ', values.cameras);

		let temp_subs_plan_total = 0;
		const temp_cameras = values.cameras;

		let per_device_discount = [];

		await temp_cameras.forEach((camera) => {
			const subscriptionPlan = subscriptionPlans.find(
				(plan) =>
					plan.subscription_plan_id === camera.subscriptionPlan &&
					plan.is_free == false
			);

			if (subscriptionPlan) {
				temp_subs_plan_total += subscriptionPlan.price;

				const abc = (
					(subscriptionPlan.price * discount_percentage) /
					100
				).toFixed(2);
				per_device_discount.push(abc);
				//   per_device_discount =(per_device_discount) + (subscriptionPlan.price * discount_percentage / 100).toFixed(2)

				console.log(
					`Camera: ${camera.cameraName}, Subscription Plan: ${camera.subscriptionPlan}, Price: ${subscriptionPlan.price}`
				);
			}
		});

		let totalPrice = 0;
		if (is_coupon_applied) {
			// adding all value from array
			const total_price = per_device_discount.reduce(
				(accumulator, currentValue) => accumulator + parseFloat(currentValue),
				0
			);
			totalPrice = temp_subs_plan_total - total_price;
		} else {
			totalPrice = temp_subs_plan_total;
		}

		console.log('totalPrice ', totalPrice);

		await cameras.forEach((camera, index) => {
			const addOnPlan = addOnPlans.find(
				(addon) => addon.addon_id === camera.addOnPlan
			);

			if (addOnPlan) {
				console.log('Add-On Plan:', addOnPlan);
				//   totalPrice += addOnPlan.addon_total_amount;
				if (camera.subscriptionPlan == 'ipcam_standard_free_trial') {
					const dis = (addOnPlan.addon_total_amount * 23.33) / 100;
					totalPrice += addOnPlan.addon_total_amount + dis;
				} else {
					totalPrice += addOnPlan.addon_total_amount;
				}
			}

			if (cameras.length - 1 == index) {
				setTotalBill(totalPrice);
			}
		});

		setTotalBill(totalPrice.toFixed(2));
	};

	return (
		<>
			{loading ? <CustomLoader loading={true} /> : null}
			<div>
				<FixedHeader />
				<section className="inner-page-title">
					<div className="container">
						<div className="row">
							<h1 className="text-center text-white">Register</h1>
						</div>
					</div>
				</section>
				<section>
					<div className="container">
						<div className="row">
							<h2 className="text-primary text-center mb-5">
								Create your account
							</h2>
						</div>

						<Formik
							innerRef={formikRef}
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>
							{({ values, setFieldValue }) => (
								<>
									<Form>
										<div className="row register-tab">
											<ul
												className="nav nav-tabs"
												id="myTab"
												role="tablist"
												style={{ display: 'none' }}
											>
												{/* <li className="nav-item" role="presentation">
													<button
														className="nav-link"
														onClick={() => {
															goToBack(values);
														}}
													>
														<i className="fa fa-user" />
														<br />
														PERSONAL &amp;
														<br />
														LOGIN DETAILS
													</button>
												</li> */}

												<li className="nav-item" role="presentation">
													<button
														className="nav-link"
														id="profile-tab"
														data-bs-toggle="tab"
														data-bs-target="#profile"
														type="button"
														role="tab"
														aria-controls="profile"
														aria-selected="false"
													>
														<i className="fa fa-credit-card" />
														<br />
														SERVICES &amp;
														<br />
														PAYMENT INFORMATION
													</button>
												</li>
											</ul>
											<section class="step-indicator">
												<div
													class="step step1 active"
													type="submit"
													onClick={() => {
														goToBack(values);
													}}
												>
													<div class="step-icon">
														<i className="fa fa-user" />
													</div>
													<p>PERSONAL &amp; LOGIN DETAILS</p>
												</div>
												<div class="indicator-line active"></div>
												<div class="step step2 active">
													<div class="step-icon">
														<i className="fa fa-credit-card" />
													</div>
													<p>
														SERVICES &amp;
														<br />
														PAYMENT INFORMATION
													</p>
												</div>
											</section>
											<div className="tab-content" id="myTabContent">
												<div
													className="tab-pane fade"
													id="profile"
													role="tabpanel"
													aria-labelledby="profile-tab"
												>
													{/* 1 */}
													<div className="register-step2-main">
														<FieldArray name="cameras">
															{({ push, remove }) => (
																<>
																	<div className="row bg-white align-items-center rounded-3 p-3 mb-3">
																		<div className="col-md-6">
																			<h4 className="mb-0">
																				<span className="text-primary fw-bold">
																					1
																				</span>{' '}
																				Billing &amp; Plan
																			</h4>
																		</div>
																		<div className="col-md-6 text-right">
																			<span className="text-primary fw-bold rounded-3 border p-2 pe-3 ps-3 d-inline border-primary">
																				Total:$ {totalBill}
																			</span>
																		</div>
																	</div>

																	{values.cameras.map((camera, index) => (
																		<div key={index} className="form-main row">
																			<div className="col-md-3">
																				<label
																					htmlFor={`cameras[${index}].cameraName`}
																				>
																					Camera Name
																				</label>
																				<Field
																					type="text"
																					name={`cameras[${index}].cameraName`}
																					className="form-control"
																				/>
																				<span className="form-icon">
																					<i className="fa fa-camera" />
																				</span>
																				<ErrorMessage
																					name={`cameras[${index}].cameraName`}
																					component="div"
																					className="text-danger"
																				/>
																			</div>

																			<div className="col-md-4">
																				<label
																					htmlFor={`cameras[${index}].subscriptionPlan`}
																				>
																					Subscription Plan
																				</label>
																				<Field
																					as="select"
																					name={`cameras[${index}].subscriptionPlan`}
																					className="form-control"
																					onChange={(event) =>
																						handleSubscriptionPlanChange(
																							event,
																							index,
																							setFieldValue,
																							values
																						)
																					}
																				>
																					<option value>Select Plan</option>
																					{subscriptionPlans.length > 0
																						? subscriptionPlans.map(
																								(subscription) =>
																									subscription.register_plan ? (
																										<option
																											key={
																												subscription.subscription_plan_id
																											}
																											value={
																												subscription.subscription_plan_id
																											}
																										>
																											{
																												subscription.subscription_plan_name
																											}
																										</option>
																									) : null
																						  )
																						: null}
																				</Field>
																				<ErrorMessage
																					name={`cameras[${index}].subscriptionPlan`}
																					component="div"
																					className="text-danger"
																				/>
																			</div>

																			<div className="col-md-4">
																				<label
																					htmlFor={`cameras[${index}].addOnPlan`}
																				>
																					Video Storage Plan
																				</label>
																				<Field
																					as="select"
																					name={`cameras[${index}].addOnPlan`}
																					className="form-control"
																					onChange={(event) =>
																						handleAddOnPlanChange(
																							event,
																							index,
																							setFieldValue,
																							values
																						)
																					}
																				>
																					<option value="">
																						Select Video Storage
																					</option>

																					{addOnPlans.length > 0
																						? addOnPlans.map((addon) => (
																								<option
																									key={addon.addon_id}
																									value={addon.addon_id}
																								>
																									{addon.addon_name}
																								</option>
																						  ))
																						: null}
																				</Field>
																				<ErrorMessage
																					name={`cameras[${index}].addOnPlan`}
																					component="div"
																					className="text-danger"
																				/>
																			</div>
																			{index != 0 ? (
																				<div
																					className="col-md-1 register-delete-icon mt-5"
																					onClick={() => {
																						remove(index);
																						setTimeout(() => {
																							calculateTotalBillOnChange(
																								formikRef.current.values,
																								discountResponse.coupon_total_amount
																							);
																						}, 100);
																					}}
																				>
																					<i className="fa fa-trash text-primary " />
																				</div>
																			) : null}
																		</div>
																	))}

																	<div className="text-center mb-4">
																		<button
																			className="btn btn-lg btn-primary"
																			onClick={() => {
																				push({
																					cameraName: '',
																					subscriptionPlan: '',
																					addOnPlan: '',
																				});
																				setTimeout(() => {
																					calculateTotalBillOnChange(
																						formikRef.current.values,
																						discountResponse.coupon_total_amount
																					);
																				}, 100);
																			}}
																		>
																			Add Another Camera
																		</button>
																	</div>
																	<div className="form-main row">
																		<div className="col-md-6">
																			<label htmlFor="discount">
																				Coupon Code
																			</label>
																			<Field
																				type="text"
																				name="discount"
																				className="form-control"
																				disabled={isDiscountApplied}
																			/>
																			{isDiscountApplied ? (
																				<label
																					htmlFor="discount"
																					className="coupon-success-msg"
																				>
																					{discountResponseMsg}
																				</label>
																			) : (
																				<label
																					htmlFor="discount"
																					className="coupon-error-msg"
																				>
																					{discountResponseMsg}
																				</label>
																			)}
																			{/* <span className="form-icon"><i className="fa fa-ellipsis" /></span>
																			 */}
																		</div>
																		<div className="col-md-6">
																			<label>&nbsp;</label>
																			<br />
																			{isDiscountApplied ? (
																				<button
																					className="btn btn-secondary"
																					onClick={() => {
																						removeCouponCode(values);
																					}}
																				>
																					Remove Coupon
																				</button>
																			) : (
																				<button
																					className="btn btn-secondary"
																					onClick={() => {
																						applyCouponCode(values);
																					}}
																				>
																					Apply Coupon
																				</button>
																			)}
																		</div>
																	</div>
																	<div className="note">
																		<span>
																			NOTE: Free Trial &amp; Coupons are not
																			applicable for Video Storage plans.
																		</span>
																	</div>
																</>
															)}
														</FieldArray>
													</div>
													{/* 1 */}
													{/* 2 */}
													<div className="register-step2-main">
														<div className="row bg-white align-items-center rounded-3 p-3 mb-3">
															<div className="col-md-6">
																<h4 className="mb-0">
																	<span className="text-primary fw-bold">
																		2
																	</span>{' '}
																	Account
																</h4>
															</div>
														</div>
														<div className="form-main row">
															<div className="col-md-6">
																<label htmlFor="country">Country</label>
																<Field
																	as="select"
																	name="country"
																	className="form-control"
																	onChange={(event) =>
																		handleCountryChange(event, setFieldValue)
																	}
																>
																	<option value="">Select Country</option>

																	{countriesList.length > 0
																		? countriesList.map((country) => (
																				<option
																					key={country.id}
																					value={country.id}
																				>
																					{country.country_name}
																				</option>
																		  ))
																		: null}
																</Field>
																<ErrorMessage
																	name="country"
																	component="div"
																	className="text-danger"
																/>
															</div>
															<div className="col-md-6">
																<label>State</label>
																{isUnitedStateSelected ? (
																	<>
																		<Field
																			as="select"
																			name="state"
																			className="form-control"
																			onChange={(event) =>
																				handleStateChange(event, setFieldValue)
																			}
																		>
																			<option value="">Select State</option>

																			{statesList.length > 0
																				? statesList.map((state) => (
																						<option
																							key={state.id}
																							value={state.id}
																						>
																							{state.state_name}
																						</option>
																				  ))
																				: null}
																		</Field>
																	</>
																) : (
																	<>
																		<Field
																			type="text"
																			name="state"
																			className="form-control"
																		/>
																		<span className="form-icon">
																			<i className="fa fa-map-marker" />
																		</span>
																	</>
																)}
																<ErrorMessage
																	name="state"
																	component="div"
																	className="text-danger"
																/>
															</div>
														</div>
														<div className="form-main row">
															<div className="col-md-6">
																<label htmlFor="address">Address</label>
																<Field
																	type="text"
																	name="address"
																	className="form-control"
																/>
																<span className="form-icon">
																	<i className="fa fa-map-marker" />
																</span>
																<ErrorMessage
																	name="address"
																	component="div"
																	className="text-danger"
																/>
															</div>

															<div className="col-md-6">
																<label htmlFor="city">City</label>
																<Field
																	type="text"
																	name="city"
																	className="form-control"
																/>
																<span className="form-icon">
																	<i className="fa fa-map-marker" />
																</span>
																<ErrorMessage
																	name="city"
																	component="div"
																	className="text-danger"
																/>
															</div>
														</div>
														<div className="form-main row">
															<div className="col-md-6">
																<label htmlFor="zip">Zip Code</label>
																<Field
																	type="text"
																	name="zip"
																	className="form-control"
																/>
																<span className="form-icon">
																	<i className="fa fa-map-marker" />
																</span>
															</div>
															<ErrorMessage
																name="zip"
																component="div"
																className="text-danger"
															/>
														</div>
													</div>
													{/* 2 */}
													{/* 3 */}
													<div className="register-step2-main pb-0">
														<div className="row bg-white align-items-center rounded-3 p-3 mb-3">
															<div className="col-md-6">
																<h4 className="mb-0">
																	<span className="text-primary fw-bold">
																		3
																	</span>{' '}
																	Payment
																</h4>
															</div>
														</div>
														<div className="form-main row">
															<div className="col-md-6">
																<label htmlFor="cardHolder">Card Holder</label>
																<Field
																	type="text"
																	name="cardHolder"
																	className="form-control"
																/>
																<span className="form-icon">
																	<i className="fa fa-credit-card" />
																</span>
																<ErrorMessage
																	name="cardHolder"
																	component="div"
																	className="text-danger"
																/>
															</div>
															<div className="col-md-6">
																<label htmlFor="cardNumber">Card Number</label>
																<Field
																	type="text"
																	name="cardNumber"
																	className="form-control"
																	onChange={(event) =>
																		handleCardNumberChange(event, setFieldValue)
																	}
																	value={formatCardNumber(values.cardNumber)}
																/>
																<span className="form-icon">
																	<i className="fa fa-credit-card-alt" />
																</span>
																<ErrorMessage
																	name="cardNumber"
																	component="div"
																	className="text-danger"
																/>
															</div>
														</div>
														<div className="form-main row">
															<div className="col-md-4">
																<label htmlFor="expiry_month">
																	Expiration Date
																</label>
																<Field
																	as="select"
																	name="expiry_month"
																	className="form-control"
																	onChange={(event) =>
																		handleExpiryMonthChange(
																			event,
																			setFieldValue
																		)
																	}
																>
																	<option value="">Select Month</option>

																	<option value="01">01</option>
																	<option value="02">02</option>
																	<option value="03">03</option>
																	<option value="04">04</option>
																	<option value="05">05</option>
																	<option value="06">06</option>
																	<option value="07">07</option>
																	<option value="08">08</option>
																	<option value="09">09</option>
																	<option value="10">10</option>
																	<option value="11">11</option>
																	<option value="12">12</option>
																</Field>
																<ErrorMessage
																	name="expiry_month"
																	component="div"
																	className="text-danger"
																/>
															</div>
															<div className="col-md-4">
																<label htmlFor="expiry_year">
																	Expiration Year
																</label>
																<Field
																	as="select"
																	name="expiry_year"
																	className="form-control"
																	onChange={(event) =>
																		handleExpiryYearChange(event, setFieldValue)
																	}
																>
																	<option value="">Select Year</option>

																	<option value="23">2023</option>
																	<option value="24">2024</option>
																	<option value="25">2025</option>
																	<option value="26">2026</option>
																	<option value="27">2027</option>
																	<option value="28">2028</option>
																	<option value="29">2029</option>
																	<option value="30">2030</option>
																	<option value="31">2031</option>
																	<option value="32">2032</option>
																	<option value="33">2033</option>
																	<option value="34">2034</option>
																	<option value="35">2035</option>
																	<option value="36">2036</option>
																	<option value="37">2037</option>
																	<option value="38">2038</option>
																	<option value="39">2039</option>
																	<option value="40">2040</option>
																</Field>

																<ErrorMessage
																	name="expiry_year"
																	component="div"
																	className="text-danger"
																/>
															</div>
															<div className="col-md-4">
																<label htmlFor="cvv">CVV</label>
																<Field
																	type={showCVV ? 'text' : 'password'}
																	name="cvv"
																	className="form-control"
																	maxLength="4"
																/>
																<span
																	className="form-icon"
																	onClick={toggleCVVVisibility}
																>
																	<i
																		className={
																			showCVV ? 'fa fa-eye-slash' : 'fa fa-eye'
																		}
																	/>
																</span>
															</div>
														</div>
														<div className="form-main row mb-4">
															<div className="col-md-12">
																<Field
																	type="checkbox"
																	name="termsAndConditions"
																/>{' '}
																I accept the{' '}
																<Link
																	to="/terms"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	terms and conditions
																</Link>
																<ErrorMessage
																	name="termsAndConditions"
																	component="div"
																	className="text-danger"
																/>
															</div>
														</div>
														<div className="form-main row">
															<div className="col-md-6">
																<button
																	className="btn btn-secondary"
																	onClick={() => {
																		goToBack(values);
																	}}
																>
																	Back
																</button>
															</div>
															<div className="col-md-6 text-right">
																<button
																	type="submit"
																	className="btn btn-primary"
																>
																	Register
																</button>
															</div>
														</div>
													</div>
													{/* 3 */}
												</div>
											</div>
										</div>
									</Form>
								</>
							)}
						</Formik>

						<div className="text-center">
							Already have an account? <Link to="/login">Log in</Link>
						</div>
					</div>
				</section>
				<footer>
					Copyright © 2023 IPCamStream
					<footer></footer>
				</footer>
			</div>
		</>
	);
};

export default RegisterStep2;
