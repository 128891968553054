// RegisterStep1.js
import '../register/RegisterStep1.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { contactUs, deviceList, getSubscriptionPlans, subscriptionCancelApi } from '../../api/common/CommonApi';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import CustomLoader from '../../components/utils/CustomLoader';
import FixedHeader from '../../components/fixed-header/FixedHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubscriptionCancel = () => {

  const userLoginData = useSelector(
    (state) => state.userLogin.userLoginResponse
  );
  const [userLoginDetails, setUserLoginDetails] = useState(
    userLoginData ? userLoginData.user : {}
  );
  const token = userLoginData.token;
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
   const { addToast } = useToasts();
  const transitionRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [addOnPlans, setAddOnPlans] = useState([]);
  const [devicesList, setDevicesList] = useState([])

  

  const initialValues = {
    full_name: "",
    email: "",
    password: "",
    cameras: "",
    reason: "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object({
    full_name: Yup.string().required('First Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
    cameras: Yup.string().required('Camera is required'),
    reason: Yup.string().required('Reason is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });

  useEffect(() => {

    let fullName = "";

    if (userLoginDetails.first_name) {
      fullName += userLoginDetails.first_name;
    }

    if (userLoginDetails.last_name) {
      if (fullName) {
        fullName += " ";
      }
      fullName += userLoginDetails.last_name;
    }

    initialValues.full_name = fullName;
    initialValues.email = userLoginDetails.email;

    getSubscriptionPlansApi();
    getDevices();
  }, []);


  const onSubmit = async (values) => {

    const request_data = {
      name: values.full_name,
      email: values.email,
      password: values.password,
      subscription_id: values.cameras,
      reason: values.reason,
      subject: values.subject,
      message: values.message,
    };

    console.log("request_data ", request_data);
    setLoading(true);

    try {
            const response = await subscriptionCancelApi(request_data);
            setLoading(false);
            if(response.status_code == 200)
            {
                addToast(response.message, { appearance: 'success' });
            }
            else{
                addToast(response.message, { appearance: 'error' });
            }
            
        } catch (error) {
            setLoading(false);
        // Handle error
        if (error.status_code === 404) {
            // Handle 404 Not Found error
            } else if (error.status_code === 500) {
            // Handle 500 Internal Server Error
            } else {
            // Handle other error cases
            }
        }

    
  };

  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getSubscriptionPlansApi = async () =>{

    try {
        const subscription_plans = await getSubscriptionPlans();

        setSubscriptionPlans(subscription_plans.plans);

    } catch (error) {
      // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
    
  }

  const handleSubscriptionPlanChange = (event, setFieldValue, values) => {
    const selectedValue = event.target.value;
    console.log('Selected Camera:', selectedValue);
    
    setFieldValue('cameras', selectedValue);

  };

  const handleReasonChange = (event, setFieldValue, values) => {
    const selectedValue = event.target.value;
    console.log('Selected handleReasonChange Plan:', selectedValue);
    
    setFieldValue('reason', selectedValue);

  };

  

  const getDevices = async () =>{
    console.log("getDevices");
    
    
    let request_data = {
        customer_id : userLoginDetails.id
    }

    console.log("request_data ", request_data);

    setLoading(true);
    try 
    {
        const response = await deviceList(request_data, token);
        console.log("response deviceList ", response);
        setLoading(false);
        if(response.status_code == 200)
        {
            // addToast(response.message, { appearance: 'success' });
            setDevicesList(response.device)
        }
        else if(response.status_code == 400){
            console.log("response", response.message);
            addToast(response.message, { appearance: 'error' });
        }
        // setStatesList(checkedDiscount.states);
    } 
    catch (error) 
    {
      setLoading(true);
        // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
  }

 

  return (
    <>

        {loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}

      <div>
        <FixedHeader/>
        <section className="inner-page-title">
          <div className="container">
            <div className="row">
              <h1 className="text-center text-white">Subscription Cancel</h1>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            {/* <div className="row">
              <h2 className="text-primary text-center mb-5">Create your account</h2>
            </div> */}
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                  <>

                    <Form>
                      
                      <div className="row register-tab">

                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            {/* <h4 className="text-center mb-3">Personal Details</h4> */}
                            <div className="form-main row">
                              <div className="col-md-6">
                                <label>Full Name <sup>*</sup></label>
                                <Field type="text" name="full_name" className="form-control" /><span className="form-icon"><i className="fa fa-user" /></span>
                                <ErrorMessage name="full_name" component="div" className="text-danger" />
                              </div>
                              <div className="col-md-6">
                                <label>EMAIL <sup>*</sup></label>
                                <Field type="email" name="email" className="form-control" /><span className="form-icon"><i className="fa fa-envelope" /></span>
                                <ErrorMessage name="email" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="form-main row">
                              <div className="col-md-6">
                                <label>PASSWORD <sup>*</sup></label>
                                <Field type={showPassword ? 'text' : 'password'} name="password" className="form-control" />
                                <span className="form-icon" onClick={togglePasswordVisibility}><i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} /></span>
                                <ErrorMessage name="password" component="div" className="text-danger" />
                              </div>

                              <div className="col-md-6">
                                <label htmlFor='cameras'>Camera</label>
                                        <Field as="select" name='cameras' className="form-control"
                                                            onChange={(event) => handleSubscriptionPlanChange(event, setFieldValue, values)}>
                                          <option value>Select Camera</option>
                                          {devicesList && devicesList.length > 0 ? (
                                            devicesList.map((device) =>
                                                device.is_enabled === true ? (
                                                <option key={device.subscription_id} value={device.subscription_id}>
                                                    {device.device_name}
                                                </option>
                                                ) : null
                                            )
                                            ) : (
                                            <option value="">No cameras available</option>
                                          )}
                                        </Field>
                                        <ErrorMessage name="cameras" component="div" className="text-danger" />
                                      </div>
                            </div>
                            <div className="form-main row">
                              <div className="col-md-12">
                                <label>REASON <sup>*</sup></label>
                                <Field as="select" name='reason' className="form-control"
                                                            onChange={(event) => handleReasonChange(event, setFieldValue, values)}>
                                    <option value>Select Reason</option>
                                    <option value="Didn't work as expected">Didn't work as expected</option>
                                    <option value="Price concerns">Price concerns</option>
                                    <option value="Poor support">Poor support</option>
                                    <option value="Not satisfied with service">Not satisfied with service</option>
                                    <option value="Other (please specify in comments section)">Other (please specify in comments section)</option>
                                </Field>
                                <ErrorMessage name="reason" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="form-main row">
                              <div className="col-md-12">
                                <label>SUBJECT <sup>*</sup></label>
                                <Field type="text" name="subject" className="form-control" />
                                <ErrorMessage name="subject" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="form-main row">
                              <div className="col-md-12">
                                <label>MESSAGE <sup>*</sup></label>
                                <Field style={{height:"150px", resize: "none"}} as="textarea" name="message" className="form-control" />
                                <ErrorMessage name="message" component="div" className="text-danger" />
                              </div>
                            </div>
                            <div className="text-center">
                              <button type="submit" className="btn btn-lg btn-primary">Send Message</button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </Form>

                  </>
            )}
            </Formik>
            
            {/* <div className="text-center">Already have an account? <Link to="/login">Log in</Link></div> */}
          </div>
        </section>
        <footer>
          Copyright © 2023 IPCamStream
          <footer>
          </footer></footer></div>
    </>
  );
};

export default SubscriptionCancel;
