import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

function CameraLive() {

    const cameraDetailsData = useSelector(
        (state) => state.cameraDetails
    );

  
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [publicUrl, setPublicUrl] = useState("");

    useEffect(() => {
        let iframe_player = cameraDetailsData?.camera_response?.iframe_player   
        const srcValue = iframe_player.match(/src="(.*?)"/)[1];
        console.log(srcValue); 
        setPublicUrl(srcValue);

    }, [])
    
    
    return (
        <div>
            <div style={{textAlign:"center", marginTop:"20px"}}>
                <div>{publicUrl}</div>
                <iframe src={publicUrl} style={{width:"100%", height:"680px"}} />
            </div>
        </div>
    )
}

export default CameraLive