import "./StreamTest.css";

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from "react";
import { deviceList, enableDevice, startStream } from "../../../api/common/CommonApi";
import { useDispatch, useSelector } from "react-redux";

import CustomLoader from "../../../components/utils/CustomLoader";
import FixedHeaderDashboard from "../../../components/fixed-header-dashboard/FixedHeaderDashboard";
import Hls from 'hls.js'
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

function StreamTest() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const userLoginData = useSelector(
    (state) => state.userLogin.userLoginResponse
  );
  const [userSubscription, setUserSubscription] = useState(
    userLoginData ? userLoginData.subscription : {}
  );
  const [userLoginDetails, setUserLoginDetails] = useState(
    userLoginData ? userLoginData.user : {}
  );
  const token = userLoginData.token;

  const navigate = useNavigate();
  const parentDivPlayerRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const iframeRef = useRef(null);
  const [devicesList, setDevicesList] = useState([])
  const [streamStarted, setStreamStarted] = useState(false)
  const [submitValues, setSubmitValues] = useState({});
  const [iframeStreamUrl, setIframeStreamUrl] = useState("");
  const initialValues = {
            stream_url: '',
            device_id: '',
        };

  useEffect(() => {
    var baseUrl = window.location.protocol + "//" + window.location.host + "/";

    console.log(baseUrl);
    console.log("userLoginData ", userLoginData);
    console.log("userSubscription ", userSubscription);
    getDevices();
  }, []);

  const validationSchema = Yup.object({
    stream_url: Yup.string().required('Camera URL is required'),
    device_id: Yup.string().required('Please select a camera'),
  });

  const getDevices = async () =>{
    console.log("getDevices");
    
    
    let request_data = {
        customer_id : userLoginDetails.id
    }

    console.log("request_data ", request_data);

    setLoading(true);
    try 
    {
        const response = await deviceList(request_data, token);
        console.log("response deviceList ", response);
        setLoading(false);
        if(response.status_code == 200)
        {
            // addToast(response.message, { appearance: 'success' });
            setDevicesList(response.device)
        }
        else if(response.status_code == 400){
            console.log("response", response.message);
            addToast(response.message, { appearance: 'error' });
        }
        // setStatesList(checkedDiscount.states);
    } 
    catch (error) 
    {
      setLoading(true);
        // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
  }

  const handleSubmit = async (values) => {
    // Handle form submission here
    console.log(values);

    setSubmitValues(values);

    

    

    setLoading(true);
    let request_data = {
        rtsp_channel_id : values.device_id,
        rtsp_url : values.stream_url,
        customer_id : userLoginDetails.customer_id
    }

    console.log("request_data ", request_data);

    try 
    {
        const response = await startStream(request_data);
        console.log("response register ", response);
        
        if(response.status_code == 200)
        {
            console.log("response", response.message);
            console.log("iframe_player ", response.iframe_player)
           setTimeout(() => {
              setLoading(false);
              addToast(response.message, { appearance: 'success' });
              setStreamStarted(true);
             displayStream(values.device_id)
           }, 25000);

        }
        else if(response.status_code == 400){
            setLoading(false);
            setStreamStarted(false);
            console.log("response", response.message);
            addToast(response.message, { appearance: 'error' });
        }
        // setStatesList(checkedDiscount.states);
    } 
    catch (error) 
    {
      setLoading(false);
      setStreamStarted(false);
        // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
  };

  const displayStream = (rtsp_channel_id) =>{

    let baseUrl = window.location.protocol + "//" + window.location.host + "/";
    
    let iframe_stream_url= baseUrl + "streams/play/" +userLoginDetails.customer_id+"/"+rtsp_channel_id;
    console.log("iframe_stream_url ", iframe_stream_url)
    // let iframe = iframeRef.current;
    // iframe.src = iframe_stream_url;
    // console.log("iframe ", iframe)
    setIframeStreamUrl(iframe_stream_url);

    setTimeout(() => {
      // Scroll to the parent div when the button is clicked
      parentDivPlayerRef.current.scrollIntoView({
        behavior: 'smooth', // You can change this to 'auto' for instant scrolling
        block: 'start',     // You can change this to 'end' to scroll to the bottom
      });
    }, 1000);
  }

  const addNewCamera = async () =>{
    console.log("addNewCamera");
    
    let baseUrl = window.location.protocol + "//" + window.location.host + "/";

    console.log(baseUrl);

    let iframe_player = `<iframe src="` + baseUrl + `streams/play/` +userLoginDetails.customer_id+`/`+submitValues.device_id + `"></iframe>`;

    let request_data = {
        customer_id : userSubscription[0].customer_id,
        device_id : submitValues.device_id,
        stream_url : submitValues.stream_url,
        iframe_player : iframe_player,
        is_enabled : true
    }

    console.log("request_data ", request_data);

    // const selected_camera_obj = await devicesList.map((device)=>{
    //   if(device.device_id == submitValues.device_id){
    //     return device;
    //   }
    // })

    const selected_camera_obj = await devicesList.find((device) => {
      return device.device_id === submitValues.device_id;
    });

    console.log("selected_camera_obj ", selected_camera_obj);

    console.log("selected_camera_obj ", selected_camera_obj["id"]);

    const device_pk_id = selected_camera_obj["id"];
    setLoading(true);
    try 
    {
        const response = await enableDevice(request_data, device_pk_id, token);
        console.log("response register ", response);
        setLoading(false);
        if(response.status_code == 200)
        {
            addToast(response.message, { appearance: 'success' });
            navigate("/cameras");
        }
        else if(response.status_code == 400){
            console.log("response", response.message);
            addToast(response.message, { appearance: 'error' });
        }
    } 
    catch (error) 
    {
      setLoading(true);
      if (error.status_code === 404) {
        } else if (error.status_code === 500) {
        } else {
        }
    }

  }

  const handleCameraChange = (event, setFieldValue) =>{
    const selectedValue = event.target.value;
    console.log("selectedValue ", selectedValue)

    setFieldValue('device_id', selectedValue);

  }


  return (
    <div>
      {loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}
      <FixedHeaderDashboard />
      <div>
        <div className="wrap--gray-lightest">
          <div className="dashboard-page-title">Test New Camera</div>
        </div>

        <div className="dashboard-container">
          <div>
            Here you can try your RTSP/RTSPS/RTMP/RTMPS/HTTP video stream before
            adding it to your account. Example of usage:
          </div>
          <div className="camera-example-container mt-1">
            <div>rtsp://user:pass@mydomain.com:554/h264</div>
            <div>rtmp://user:pass@mydomain.com:1935/ch0</div>
            <div>http://user:pass@mydomain.com/mjpeg</div>
          </div>
          <div className="mt-5">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
            {({ values, setFieldValue }) => (
                  <>

                   <Form>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <label htmlFor="device_id">Select Camera:</label>
                            <Field
                                as="select"
                                id="device_id"
                                name="device_id"
                                className="form-control my-2"
                                onChange={(event) => handleCameraChange(event, setFieldValue)}
                            >
                                <option value="">Select Camera</option>
                                {devicesList && devicesList.length > 0 ? (
                                                devicesList.map((device) =>
                                                    device.is_enabled === false ? (
                                                    <option key={device.device_id} value={device.device_id}>
                                                        {device.device_name}
                                                    </option>
                                                    ) : null
                                                )
                                                ) : (
                                                <option value="">No cameras available</option>
                                              )}
                            </Field>
                            <ErrorMessage name="device_id" component="div" className="text-danger" />
                        </div>
                        <div className="col-lg-9 col-md-6 col-sm-12">
                            <label htmlFor="stream_url">URL of your camera:</label>
                            <Field
                                type="text"
                                id="stream_url"
                                name="stream_url"
                                className="form-control my-2"
                            />
                            <ErrorMessage name="stream_url" component="div" className="text-danger" />
                        </div>
                    </div>
                    <div
                        style={{ display: "flex", justifyContent: "center" }}
                        className="orange-btn"
                    >
                        <button type="submit" className="btn btn-primary mt-2">
                        <i
                            className="fa fa-search mr-4"
                            style={{ marginRight: "10px" }}
                        ></i>{" "}
                        Search
                        </button>
                    </div>
                    </Form>

                   </>
              )}
               
            </Formik>
          </div>

          {streamStarted && iframeStreamUrl ? (
            <div ref={parentDivPlayerRef} className="mt-5">
              <div className="mx-1">
    
                <iframe src={iframeStreamUrl} style={{width:"100%", height:"680px"}} />
                {/* <video ref={videoRef} style={{height: "650px"}}></video> */}
              </div>

              <div
                    style={{ display: "flex", justifyContent: "center" }}
                    className="orange-btn"
                >
                    <button type="button" className="btn btn-primary mt-2" onClick={()=>{addNewCamera()}}>
                    <i
                        className="fa fa-plus mr-4"
                        style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Add Camera
                    </button>
                </div>
            </div>
          ) : null}

          
        </div>
      </div>
    </div>
  );
}

export default StreamTest;
