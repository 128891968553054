import './../fixed-header-pricing/FixedHeaderPricing.css';

import React from 'react';

function FixedHeaderPricing() {
	return (
		<div className="heading-wrapper header">
			<div className="container text-container">
				<div className="text-white text-center center-text auto-center">
					<h1>Features & Pricing</h1>
					<p>
						Zero upfront, cancel anytime . No long term commitment . 7 days free
						trial
					</p>
				</div>
			</div>
		</div>
	);
}

export default FixedHeaderPricing;
