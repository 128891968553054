import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

function SharingCamera() {

    const cameraDetailsData = useSelector(
        (state) => state.cameraDetails
    );

  
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [publicUrl, setPublicUrl] = useState("");
    const [iframePayer, setIframePlayer] = useState("");



    useEffect(() => {
        let iframe_player = cameraDetailsData?.camera_response?.iframe_player;
        setIframePlayer(iframe_player);

        const srcValue = iframe_player.match(/src="(.*?)"/)[1];
        console.log(srcValue); 
        setPublicUrl(srcValue);

    }, [])
    
    
    console.log("Sharing inside ");
  return (
    <div>
        <div style={{textAlign: 'center', fontWeight: 300, color: '#7e7e7e', padding:"40px 0px", fontSize: "38px"}}>Public camera page</div>
        <div style={{ fontWeight: 300,  padding: '30px 0', fontSize: '20px' }}>Copy Iframe code to integrate into your website:</div>
        <div style={{ fontWeight: 400, color:"rgb(255, 90, 0)",  padding: '15px 10px', fontSize: '20px', background:"#e9e9e9" }}>{iframePayer}</div>

        <div style={{ fontWeight: 300,  padding: '30px 0', fontSize: '20px' }}>You can <strong>share the live video</strong> of the camera with a simple link:</div>
        <div style={{ fontWeight: 400, color:"rgb(255, 90, 0)",  padding: '15px 10px', fontSize: '20px', background:"#e9e9e9" }}>{publicUrl}</div>
    </div>
  )
}

export default SharingCamera