import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import CameraDelete from '../camera-delete/CameraDelete';
import CameraLive from '../camera-live/CameraLive';
import CameraSetting from '../camera-setting/CameraSetting';
import LivePlayer from '../../liveplayer/LivePlayer';
import PlaybackPlayer from '../../playbackplayer/PlaybackPlayer';
import SharingCamera from '../sharing/SharingCamera';
import { setCameraDetails } from '../../../../redux/slices/cameraDetailSlice';
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const CameraDetailsTab = () => {

  const cameraDetailsData = useSelector(
    (state) => state.cameraDetails
  );


    const { addToast } = useToasts();
    const navigate = useNavigate();
    const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(cameraDetailsData?.camera_tab_name);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log("tab", tab)
    dispatch(setCameraDetails({
      camera_response: cameraDetailsData?.camera_response, 
      camera_tab_name : tab
    }));
  };

  useEffect(() => {
    console.log("activeTab ", activeTab);
  
  }, [])
  

  return (
    <div>
      <ul className="nav nav-tabs justify-content-center">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Live' ? 'active' : ''}`}
            onClick={() => handleTabClick('Live')}
            style={{
              color: activeTab === 'Live' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Live
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Playback' ? 'active' : ''}`}
            onClick={() => handleTabClick('Playback')}
            style={{
              color: activeTab === 'Playback' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Playback
          </button>
        </li>
        {/* <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Time-lapse' ? 'active' : ''}`}
            onClick={() => handleTabClick('Time-lapse')}
            style={{
              color: activeTab === 'Time-lapse' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Time-lapse
          </button>
        </li> */}
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Sharing' ? 'active' : ''}`}
            onClick={() => handleTabClick('Sharing')}
            style={{
              color: activeTab === 'Sharing' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Sharing
          </button>
        </li>
        {/* <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Statistics' ? 'active' : ''}`}
            onClick={() => handleTabClick('Statistics')}
            style={{
              color: activeTab === 'Statistics' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Statistics
          </button>
        </li> */}
        {/* <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Status' ? 'active' : ''}`}
            onClick={() => handleTabClick('Status')}
            style={{
              color: activeTab === 'Status' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Status
          </button>
        </li> */}
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Settings' ? 'active' : ''}`}
            onClick={() => handleTabClick('Settings')}
            style={{
              color: activeTab === 'Settings' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Settings
          </button>
        </li>
        {/* <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Firewall' ? 'active' : ''}`}
            onClick={() => handleTabClick('Firewall')}
            style={{
              color: activeTab === 'Firewall' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Firewall
          </button>
        </li> */}
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'Delete' ? 'active' : ''}`}
            onClick={() => handleTabClick('Delete')}
            style={{
              color: activeTab === 'Delete' ? '#ff5a00' : '#3f3f3f',
              padding: '10px 40px',
            }}
          >
            Delete
          </button>
        </li>
      </ul>

      {activeTab === 'Live' && <CameraLive />}
      {activeTab === 'Playback' && <PlaybackPlayer />}
      {activeTab === 'Sharing' && <SharingCamera />}
      {activeTab === 'Settings' && <CameraSetting />}
      {activeTab === 'Delete' && <CameraDelete />}
    </div>
  );
};

export default CameraDetailsTab;
