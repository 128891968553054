// RegisterStep1.js
import '../register/RegisterStep1.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { checkDiscount, contactUs, deviceList, getAddOnPlans, getSubscriptionPlans, subscriptionCreateApi } from '../../api/common/CommonApi';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import CustomLoader from '../../components/utils/CustomLoader';
import FixedHeader from '../../components/fixed-header/FixedHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { produce } from 'immer';

const SubscriptionCreate = () => {

  const userLoginData = useSelector(
    (state) => state.userLogin.userLoginResponse
  );
  const [userLoginDetails, setUserLoginDetails] = useState(
    userLoginData ? userLoginData.user : {}
  );
  const token = userLoginData.token;
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
   const { addToast } = useToasts();
  const transitionRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [addOnPlans, setAddOnPlans] = useState([]);

  const [totalBill, setTotalBill] = useState(0);

  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [discountResponseMsg, setDiscountResponseMsg] = useState("");
  const [discountResponse, setDiscountResponse] = useState({});

  const formikRef = useRef();


  const initialValues = {
    cameras: [{ cameraName: '', subscriptionPlan: '', addOnPlan: '' }],
    discount: "",
  };

  const validationSchema = Yup.object({
    cameras: Yup.array().of(
        Yup.object().shape({
        cameraName: Yup.string().required('Camera Name is required'),
        subscriptionPlan: Yup.string().required('Subscription Plan is required'),
        addOnPlan: Yup.string()
        })
    ),

    discount: Yup.string(),

  });

  useEffect(() => {

    let fullName = "";

    if (userLoginDetails.first_name) {
      fullName += userLoginDetails.first_name;
    }

    if (userLoginDetails.last_name) {
      if (fullName) {
        fullName += " ";
      }
      fullName += userLoginDetails.last_name;
    }

    
    // initialValues.cameras = [{ cameraName: '', subscriptionPlan: '', addOnPlan: '' }];
    // initialValues.discount = "";

    const fetchData = async () => {
        try {

            console.log("calling")
            const [subscriptionPlansResponse, addOnPlansResponse, countriesResponse, statesResponse] = await Promise.all([
                getSubscriptionPlansApi(),
                fetchAddOnPlans(),
            ]);

        } catch (error) {
        // Handle error
        }
    };

    fetchData();
    
  }, []);


  const onSubmit = async (values) => {

    console.log("values ", values);
    const plan_selected = values.cameras[0];
    console.log("plan_selected ", plan_selected)

    const request_data = {
        "email":userLoginDetails.email,
        "service_plan":plan_selected.subscriptionPlan,
        "total_price":totalBill,
        "add_on":plan_selected.addOnPlan,
        "discount": values.discount,
        // "discount_amount":4.99,
        "device_name": plan_selected.cameraName,
    }

    console.log("request_data ", request_data);
    setLoading(true);

    try 
    {
            const response = await subscriptionCreateApi(request_data);
            setLoading(false);
            if(response.status_code == 200)
            {
                addToast(response.message, { appearance: 'success' });
                navigate("/streamtest");
            }
            else{
                addToast(response.message, { appearance: 'error' });
            }
            
    } 
    catch (error) {
        setLoading(false);
        // Handle error
        if (error.status_code === 404) 
        {
            // Handle 404 Not Found error
        } 
        else if (error.status_code === 500) 
        {
            // Handle 500 Internal Server Error
        } 
        else 
        {
            // Handle other error cases
        }
    }

    
  };

  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getSubscriptionPlansApi = async () =>{

    try {
        const subscription_plans = await getSubscriptionPlans();

        setSubscriptionPlans(subscription_plans.plans);

    } catch (error) {
      // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
    
  }

  const fetchAddOnPlans = async () => {
    // Make API call to fetch add-on plans
    // Assuming it returns an array of add-on plans
    
    try {
        const fetchedAddOnPlans = await getAddOnPlans();
        // console.log(fetchedAddOnPlans.plans);
        setAddOnPlans(fetchedAddOnPlans.plans);
    } catch (error) {
        // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
  };


  const handleSubscriptionPlanChange = (event, index, setFieldValue, values) => {
    const selectedValue = event.target.value;
    console.log('Selected Subscription Plan:', selectedValue);

    const updatedCameras = produce(values.cameras, (draftCameras) => {
        draftCameras[index].subscriptionPlan = selectedValue;
    });

    setFieldValue(`cameras[${index}].subscriptionPlan`, selectedValue);
    setFieldValue('cameras', updatedCameras);

    console.log("discountResponse ", discountResponse)


    setTimeout(() => {
        calculateTotalBillOnChange(formikRef.current.values, discountResponse.coupon_total_amount)
    }, 100);


    };


const handleAddOnPlanChange = (event, index, setFieldValue, values) => {
  const selectedValue = event.target.value;
  console.log('Selected Add-On Plan:', selectedValue);

  const updatedCameras = produce(values.cameras, (draftCameras) => {
    draftCameras[index].addOnPlan = selectedValue;
  });

  setFieldValue(`cameras[${index}].addOnPlan`, selectedValue);
  setFieldValue('cameras', updatedCameras);

  setTimeout(() => {
    calculateTotalBillOnChange(formikRef.current.values, discountResponse.coupon_total_amount)
  }, 100);

//   console.log('Updated Values:', values.cameras);

};

const removeCouponCode = (values) =>{
    setIsDiscountApplied(false);
    setDiscountResponseMsg("");
    setDiscountResponse({})

    console.log(formikRef.current.values)
    setTimeout(() => {
        const updatedValues = { ...formikRef.current.values, isDiscountApplied:false, discountResponseMsg: "" };

        calculateTotalBill(formikRef.current.values, 100, false);
    }, 100);

    
    
}

const applyCouponCode = async (values) =>{
    // API call for discount

    const discount = values.discount;

    let request_data = {
        "coupon_id" : discount
    }

    setLoading(true);

    try {
        const response = await checkDiscount(request_data);
        console.log("checkedDiscount ", response);
        setLoading(false);
        if(response.status_code == 200)
        {
            setIsDiscountApplied(true);
            setDiscountResponseMsg(response.message);
            setDiscountResponse(response.Discount)

            setTimeout(async () => {
                const updatedValues = { ...formikRef.current.values, isDiscountApplied:true, discountResponseMsg: response.message};

                calculateTotalBill(formikRef.current.values, response.Discount.coupon_total_amount, true);
            }, 100);

            
            

        }
        else if(response.status_code == 400){
            setIsDiscountApplied(false);
            setDiscountResponseMsg(response.message);
        }
        
        
        // setStatesList(checkedDiscount.states);
    } catch (error) {
        // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }

}

const calculateTotalBillOnChange = async (values, discount_percentage) => {

  let cameras = [...values.cameras];

  console.log("values ", values.cameras);

  let temp_subs_plan_total = 0;
  const temp_cameras = values.cameras;

  let per_device_discount = [];

  await temp_cameras.forEach((camera) => {
    const subscriptionPlan = subscriptionPlans.find(
      (plan) => plan.subscription_plan_id === camera.subscriptionPlan && plan.is_free == false
    );

    if (subscriptionPlan) {
      temp_subs_plan_total += subscriptionPlan.price;

      const abc = (subscriptionPlan.price * discount_percentage / 100).toFixed(2);
      per_device_discount.push(abc);

      console.log(
        `Camera: ${camera.cameraName}, Subscription Plan: ${camera.subscriptionPlan}, Price: ${subscriptionPlan.price}`
      );
    }
  });

    let totalPrice = 0;
    console.log("isDiscountApplied ", isDiscountApplied);
    if(isDiscountApplied)
    {
        // adding all value from array
        const total_price = per_device_discount.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
        totalPrice = temp_subs_plan_total - total_price;
    }
    else
    {
        totalPrice = temp_subs_plan_total;
    }

  console.log("totalPrice ", totalPrice);

  await cameras.forEach((camera, index) => {
    const addOnPlan = addOnPlans.find((addon) => addon.addon_id === camera.addOnPlan);
    // console.log("addOnPlan obj ", addOnPlan);

    if (addOnPlan) {
      console.log('Add-On Plan:', addOnPlan, camera.subscriptionPlan);
      if(camera.subscriptionPlan == "ipcam_standard_free_trial")
      {
        const dis = ((addOnPlan.addon_total_amount * 23.33) / 100)
        totalPrice += addOnPlan.addon_total_amount + dis;
      }
      else
      {
        totalPrice += addOnPlan.addon_total_amount;
      }
      
    }

    if(cameras.length-1 == index){
        setTotalBill(totalPrice);
    }

  });
  
  setTotalBill(totalPrice.toFixed(2));
};

const calculateTotalBill = async (values, discount_percentage, is_coupon_applied) => {

  let cameras = [...values.cameras];

  console.log("values ", values.cameras);

  let temp_subs_plan_total = 0;
  const temp_cameras = values.cameras;

  let per_device_discount = [];

  await temp_cameras.forEach((camera) => {
    const subscriptionPlan = subscriptionPlans.find(
      (plan) => plan.subscription_plan_id === camera.subscriptionPlan && plan.is_free == false
    );

    if (subscriptionPlan) {
      temp_subs_plan_total += subscriptionPlan.price;

      const abc = (subscriptionPlan.price * discount_percentage / 100).toFixed(2);
      per_device_discount.push(abc);
    //   per_device_discount =(per_device_discount) + (subscriptionPlan.price * discount_percentage / 100).toFixed(2)

      console.log(
        `Camera: ${camera.cameraName}, Subscription Plan: ${camera.subscriptionPlan}, Price: ${subscriptionPlan.price}`
      );
    }
  });

    let totalPrice = 0;
    if(is_coupon_applied)
    {
        // adding all value from array
        const total_price = per_device_discount.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
        totalPrice = temp_subs_plan_total - total_price;
    }
    else
    {
        totalPrice = temp_subs_plan_total;
    }

  console.log("totalPrice ", totalPrice);

  

  await cameras.forEach((camera, index) => {
    const addOnPlan = addOnPlans.find((addon) => addon.addon_id === camera.addOnPlan);

    if (addOnPlan) {
      console.log('Add-On Plan:', addOnPlan);
    //   totalPrice += addOnPlan.addon_total_amount;
      if(camera.subscriptionPlan == "ipcam_standard_free_trial")
      {
        const dis = ((addOnPlan.addon_total_amount * 23.33) / 100)
        totalPrice += addOnPlan.addon_total_amount + dis;
      }
      else
      {
        totalPrice += addOnPlan.addon_total_amount;
      }
    }

    if(cameras.length-1 == index){
        setTotalBill(totalPrice);
    }

  });
  
  setTotalBill(totalPrice.toFixed(2));
};

 

  return (
    <>

        {loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}

      <div>
        <FixedHeader/>
        <section className="inner-page-title">
          <div className="container">
            <div className="row">
              <h1 className="text-center text-white">Subscription Create</h1>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            {/* <div className="row">
              <h2 className="text-primary text-center mb-5">Create your account</h2>
            </div> */}
            
            <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                  <>

                    <Form>
                      
                      <div className="row register-tab">

                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            {/* <h4 className="text-center mb-3">Personal Details</h4> */}
                            
                            <FieldArray name="cameras">
                                {({ push, remove }) => (
                                <>
                                  
                                  <div className="row bg-white align-items-center rounded-3 p-3 mb-3">
                                    <div className="col-md-6"><h4 className="mb-0"><span className="text-primary fw-bold">1.</span> Plan</h4>
                                    </div>
                                    <div className="col-md-6 text-right"><span className="text-primary fw-bold rounded-3 border p-2 pe-3 ps-3 d-inline border-primary">Total:$ {totalBill}</span></div>
                                  </div>

                                  {values.cameras.map((camera, index) => (
                                    
                                    <div key={index} className="form-main row">
                                      <div className="col-md-3"><label htmlFor={`cameras[${index}].cameraName`}>Camera Name</label>
                                        <Field type="text" name={`cameras[${index}].cameraName`} className="form-control" />
                                        <span className="form-icon"><i className="fa fa-camera" /></span>
                                        <ErrorMessage name={`cameras[${index}].cameraName`} component="div" className="text-danger" />
                                      </div>

                                      <div className="col-md-4"><label htmlFor={`cameras[${index}].subscriptionPlan`}>Subscription Plan</label>
                                        <Field as="select" name={`cameras[${index}].subscriptionPlan`} className="form-control"
                                                            onChange={(event) => handleSubscriptionPlanChange(event, index, setFieldValue, values)}>
                                          <option value>Select Plan</option>
                                          {subscriptionPlans.length > 0 ? (
                                                                    subscriptionPlans.map((subscription) => (
                                                                        subscription.register_plan == false ? (
                                                                        <option key={subscription.subscription_plan_id} value={subscription.subscription_plan_id}>
                                                                            {subscription.subscription_plan_name}
                                                                        </option>
                                                                        ) : null
                                                                    ))
                                                                ) : null}
                                        </Field>
                                        <ErrorMessage name={`cameras[${index}].subscriptionPlan`} component="div" className="text-danger" />
                                      </div>
                                      
                                      <div className="col-md-4"><label htmlFor={`cameras[${index}].addOnPlan`}>Video Storage Plan</label>
                                        <Field as="select" name={`cameras[${index}].addOnPlan`} className="form-control"
                                                                onChange={(event) => handleAddOnPlanChange(event, index, setFieldValue, values)}>
                                                                <option value="">Select Video Storage</option>

                                                                {addOnPlans.length > 0 ? (
                                                                    addOnPlans.map((addon) => (
                                                                        <option key={addon.addon_id} value={addon.addon_id}>
                                                                        {addon.addon_name}
                                                                    </option>
                                                                    ))
                                                                ) : null }
                                          </Field>
                                          <ErrorMessage name={`cameras[${index}].addOnPlan`} component="div" className="text-danger" />
                                      </div>
                                      {index!=0 ? (
                                        <div className="col-md-1 register-delete-icon mt-5" onClick={() => {
                                                                        remove(index)
                                                                        setTimeout(() => {
                                                                            calculateTotalBillOnChange(formikRef.current.values, discountResponse.coupon_total_amount)
                                                                        }, 100);
                                                                       
                                                                    }}><i className="fa fa-trash text-primary " /></div>
                                      ) : null}
                                      
                                    </div>

                                  ))}
                                  
                                  {/* <div className="text-center mb-4">
                                    <button className="btn btn-lg btn-primary" onClick={() => {
                                                    push({ cameraName: '', subscriptionPlan: '', addOnPlan: '' });
                                                    setTimeout(() => {
                                                                            calculateTotalBillOnChange(formikRef.current.values, discountResponse.coupon_total_amount)
                                                                        }, 100);
                                                }}>Add Another Camera</button>
                                  </div> */}
                                  <div className="form-main row">
                                    <div className="col-md-6"><label htmlFor="discount">Coupon Code</label>
                                      <Field type="text" name="discount" className="form-control" disabled={isDiscountApplied} />
                                                        {isDiscountApplied ? (
                                                            <label htmlFor="discount" className='coupon-success-msg'>{discountResponseMsg}</label>
                                                        ) : (
                                                            <label htmlFor="discount" className='coupon-error-msg'>{discountResponseMsg}</label>
                                                        )}
                                      {/* <span className="form-icon"><i className="fa fa-ellipsis" /></span>
                                       */}
                                       </div>
                                    <div className="col-md-6"><label>&nbsp;</label><br />
                                       {isDiscountApplied ? (
                                        <button className="btn btn-secondary"onClick={() => {removeCouponCode(values)}}>
                                                            Remove Coupon
                                                            </button>
                                       ) : (
                                        <button className="btn btn-secondary"onClick={() => {applyCouponCode(values)}}>
                                                            Apply Coupon
                                                            </button>
                                       )}
                                      
                                    </div>
                                  </div>
                                  <div className="note"><span>NOTE: Free Trial &amp; Coupons are not applicable for Video Storage plans.</span></div>
                                  
                                </>
                                )}
                              </FieldArray>

                            <div className="text-center" style={{marginTop:"30px"}}>
                              <button type="submit" className="btn btn-lg btn-primary">Create Subscription</button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </Form>

                  </>
            )}
            </Formik>
            
            {/* <div className="text-center">Already have an account? <Link to="/login">Log in</Link></div> */}
          </div>
        </section>
        <footer>
          Copyright © 2023 IPCamStream
          <footer>
          </footer></footer></div>
    </>
  );
};

export default SubscriptionCreate;
