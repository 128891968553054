import React, { useEffect, useState } from "react";
import { decrement, increment, setName } from '../redux/slices/counterSliceData';
import { useDispatch, useSelector } from 'react-redux';

const Counter = () => {
    const count = useSelector((state) => state.counter.count);
    const name = useSelector((state) => state.counter.name);
    const dispatch = useDispatch();
    const [inputName, setInputName] = useState('');

    const handleNameChange = (event) => {
        setInputName(event.target.value);
    };

    const handleSaveName = () => {
        dispatch(setName(inputName));
        setInputName('');
    };


    return (
        <div>
            <h1>Counter: {count}</h1>
            <input type="text" value={inputName} onChange={handleNameChange} />
            <button onClick={handleSaveName}>Save Name</button>
            <h2>Saved Name: {name}</h2>
            <button onClick={() => dispatch(increment())}>Increment</button>
            <button onClick={() => dispatch(decrement())}>Decrement</button>
        </div>
    );
};

export default Counter;
