import './../views/Pricing.css';

import {
	Button,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	InputGroup,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	faCamera,
	faCircleInfo,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { getAddOnPlans, getSubscriptionPlans } from '../api/common/CommonApi';
import {
	resetUserRegister,
	setUserRegisterDataStep2,
} from '../redux/slices/userRegisterSlice';
import { useDispatch, useSelector } from 'react-redux';

import FixedFooter from '../components/fixed-footer/FixedFooter';
import FixedHeader from '../components/fixed-header/FixedHeader';
import FixedHeaderPricing from '../components/fixed-header-pricing/FixedHeaderPricing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TickImage from './../Images/OrangeTick.png';

function Pricing() {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const userRegisterData = useSelector((state) => state.userRegister);

	const [subscriptionRegisterPlans, setSubscriptionRegisterPlans] = useState([]);
    const [addOnPlans, setAddOnPlans] = useState([]);

	useEffect(() => {
		getSubscriptionPlansApi();
        fetchAddOnPlans();
	}, []);

    
    const goToRegister = () => {
      navigate('/register-step1');
    }
    

	const buyNowRegisterStandard = () => {
		const subscription_plan_id =
			subscriptionRegisterPlans &&
			subscriptionRegisterPlans[1] &&
			subscriptionRegisterPlans[1].subscription_plan_id;
		console.log('subscription_plan_id ', subscription_plan_id);

		// reset register
		dispatch(resetUserRegister());

		let register_data = {
			cameras: [
				{
					cameraName: '',
					subscriptionPlan: subscription_plan_id,
					addOnPlan: '',
				},
			],
		};

		dispatch(setUserRegisterDataStep2(register_data));

		navigate('/register-step1');
	};

	const buyNowRegisterBusiness = () => {
		const subscription_plan_id =
			subscriptionRegisterPlans &&
			subscriptionRegisterPlans[0] &&
			subscriptionRegisterPlans[0].subscription_plan_id;
		console.log('subscription_plan_id ', subscription_plan_id);

		const subscription_price =
			subscriptionRegisterPlans &&
			subscriptionRegisterPlans[0] &&
			subscriptionRegisterPlans[0].price;
		console.log('subscription_plan_id ', subscription_price);

		// reset register
		dispatch(resetUserRegister());

		let register_data = {
			totalBill: subscription_price,
			cameras: [
				{
					cameraName: '',
					subscriptionPlan: subscription_plan_id,
					addOnPlan: '',
				},
			],
		};

		dispatch(setUserRegisterDataStep2(register_data));

		navigate('/register-step1');
	};

	const getSubscriptionPlansApi = async () => {
		try {
			const subscription_plans = await getSubscriptionPlans();

			const filteredRegisterPlans = subscription_plans.plans.filter(
				(plan) => plan.register_plan === true
			);
			console.log(filteredRegisterPlans);

			setSubscriptionRegisterPlans(filteredRegisterPlans);
		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	//new code
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggleDropdown = () => {
		setDropdownOpen((prevState) => !prevState);
	};

	const [cameraComponents, setCameraComponents] = useState([
		{ key: 0, index: 0 },
	]);

	const addCamera = () => {
		setCameraComponents((prevComponents) => [
			...prevComponents,
			{ key: prevComponents.length, index: prevComponents.length },
		]);
	};

	const removeCamera = (indexToRemove) => {
		setCameraComponents((prevComponents) =>
			prevComponents.filter((camera) => camera.index !== indexToRemove)
		);
	};

    const fetchAddOnPlans = async () => {
    // Make API call to fetch add-on plans
    // Assuming it returns an array of add-on plans
    
    try {
        const fetchedAddOnPlans = await getAddOnPlans();
        // console.log(fetchedAddOnPlans.plans);
        setAddOnPlans(fetchedAddOnPlans.plans);
    } catch (error) {
        // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
  };

	return (
		<>
			{/* start header */}
			<FixedHeader />
			{/* end header */}
			{/* start pricing page*/}
			<FixedHeaderPricing />
			{/* <div className="pricing-container new-container ">
				<div className="title">
					<h2>Video Streaming & VideoStorage</h2>
					<p>
						Receiving video stream from the camera and broadcast it to the
						viewers.
					</p>
				</div>
				<div className="plans-container">
					{cameraComponents.map((camera) => (
						<CameraComponent
							key={camera.key}
							cameraIndex={camera.index}
							removeCamera={removeCamera}
						/>
					))}
					<Button className="button" onClick={addCamera}>
						Add Another Camera
					</Button>
				</div>
			</div> */}
			<div className="pricing-container new-container">
				<div className="title">
					<h2>Video Streaming</h2>
					<p>
						Receiving video stream from the camera and broadcast it to the
						viewers.
					</p>
				</div>
				<div className="card-container">
					<div className="pricing-card">
						<p>{subscriptionRegisterPlans && subscriptionRegisterPlans[1] && subscriptionRegisterPlans[1].subscription_plan_name}</p>
						<h3>Active Camera</h3>
						<h1>${subscriptionRegisterPlans && subscriptionRegisterPlans[1] && subscriptionRegisterPlans[1].price}</h1>
						<p>Billed monthly</p>
						<Button className="btn-outline" outline onClick={()=>{goToRegister()}}>
							Get Started
						</Button>
					</div>
					<div className="pricing-card">
						<div class="most-popular">MOST POPULAR</div>
						<p>{subscriptionRegisterPlans && subscriptionRegisterPlans[1] && subscriptionRegisterPlans[0].subscription_plan_name}</p>
						<h3>Active Camera</h3>
						<h1>${subscriptionRegisterPlans && subscriptionRegisterPlans[1] && subscriptionRegisterPlans[0].price}</h1>
						<p>Billed monthly</p>
						<Button className="btn-outline" outline onClick={()=>{goToRegister()}}>
							Get Started
						</Button>
					</div>
				</div>
				<div className="title">
					<h2>Video Storage</h2>
					<p>
						Video storage is an optional feature that can be activated in any
						package. IpCamStream servers record video footage from the live stream
						and you can play the last couple of hours back depending on the
						storage plan you selected.
					</p>
				</div>
				<div className="card-container">
					<div className="pricing-card">
						<p>{addOnPlans && addOnPlans[0] && addOnPlans[0].addon_name}</p>
						<h3>Active Camera</h3>
						<h1>${addOnPlans && addOnPlans[0] && addOnPlans[0].addon_total_amount}</h1>
						<p>Billed monthly</p>

						<Button className="btn-outline" outline onClick={()=>{goToRegister()}}>
							Get Started
						</Button>
					</div>
					<div className="pricing-card">
						<p>{addOnPlans && addOnPlans[1] && addOnPlans[1].addon_name}</p>
						<h3>Active Camera</h3>
						<h1>${addOnPlans && addOnPlans[1] && addOnPlans[1].addon_total_amount}</h1>
						<p>Billed monthly</p>
						<Button className="btn-outline" outline onClick={()=>{goToRegister()}}>
							Get Started
						</Button>
					</div>
					<div className="pricing-card">
						<div class="most-popular">MOST POPULAR</div>
						<p>{addOnPlans && addOnPlans[2] && addOnPlans[2].addon_name}</p>
						<h3>Active Camera</h3>
						<h1>${addOnPlans && addOnPlans[2] && addOnPlans[2].addon_total_amount}</h1>
						<p>Billed monthly</p>
						<Button className="btn-outline" outline onClick={()=>{goToRegister()}}>
							Get Started
						</Button>
					</div>
				</div>
			</div>

			<section className="service-container">
				<div className="new-container">
					<h2 className=" text-center mb-5">Service Types</h2>
					<div className="row">
						<table className="table  table--features table-responsive">
							<thead>
								<tr>
									<th>
										<div>
											<span>Features</span>
										</div>
									</th>
									<th>
										<div>
											<span>Basic</span>
										</div>
									</th>

									<th>
										<div>
											<span>Professional</span>
										</div>
									</th>
									<th>
										<div>
											<span>Ultimate</span>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Live streaming</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										On-demand streaming{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Full HD camera support{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td>
										<img className="icon auto-center" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										RTSP video stream{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										RTMP video stream{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>MJPEG/MPEG4 stream support</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>H.264 stream support</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										<strong>Embed live video into web page</strong>
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										Camera public page without ads{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										Continuous streaming{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										Embed live video into HTTPS web page{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										H.265 stream support{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										Auto play support{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Unlimited viewing time length{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										Disable camera public page{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Live video without IpCamStream logo{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Custom overlay logo support{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										E-mail notifications{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										API access{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Stream sharing{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
										<br />
										<small>
											<a href="social-media-streaming">Learn more</a>
										</small>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										<strong>Video storage</strong>
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
										<i
											className="fa fa-exclamation-circle"
											data-toggle="tooltip"
											title=""
											data-original-title="The price of the video storage feature is not included in the video streaming service"
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>

								<tr>
									<td>
										Stream domain lock{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										<strong>Audio support</strong>{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										<strong>Timelapse video</strong>{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
										<br />
										<small>
											<a href="timelapse-hub">Learn more</a>
										</small>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Time shift{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Operation time window{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Firewall IP filter support{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td>
										<img className="icon" src={TickImage} />
									</td>
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										4K Ultra HD camera support{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										<strong>Face blur</strong>{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
										<br />
										<small>
											<a href="face-blur">Learn more</a>
										</small>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Pin lock{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										<strong>Overlay Panel</strong>{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
										<br />
										<small>
											<a href="overlay-panel">Learn more</a>
										</small>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Camera navigation{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
										<br />
										<small>
											<a href="camera-navigation">Learn more</a>
										</small>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Video Ads{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
										<br />
										<small>
											<a href="video-ads">Learn more</a>
										</small>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Video snapshot{' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Customized/white labeled player
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
										<br />
										<small>
											<a href="customized-player">Learn more</a>
										</small>
									</td>
									<td />

									<td />
									<td>
										<img className="icon" src={TickImage} />
									</td>
								</tr>
								<tr>
									<td>
										Maximum stream bandwidth (mbps){' '}
										<FontAwesomeIcon
											className="fa-info-circle"
											icon={faCircleInfo}
										/>
									</td>
									<td>2</td>

									<td>4</td>
									<td>6</td>
								</tr>
								<tr></tr>
							</tbody>
						</table>
					</div>
				</div>
			</section>

			{/* end pricing page*/}
			{/* start footer */}
			<FixedFooter />
			{/* end footer */}
		</>
	);
}

export default Pricing;

const CameraComponent = ({ cameraIndex, removeCamera }) => {
	const [dropdownOpenSubscription, setDropdownOpenSubscription] =
		useState(false);
	const [dropdownOpenVideoSubscription, setdropdownOpenVideoSubscription] =
		useState(false);
	const [selectedSubscription, setSelectedSubscription] = useState(
		'Select Subscription'
	); // Add state for selected subscription
	const [selectedStorage, setSelectedStorage] = useState(
		'Select Video Storage'
	); // Add state for selected storage

	const toggleSubscriptionDropdown = () => {
		setDropdownOpenSubscription((prevState) => !prevState);
	};
	const toggleVideoSubscriptionDropdown = () => {
		setdropdownOpenVideoSubscription((prevState) => !prevState);
	};
	const handleSubscriptionSelect = (subscription) => {
		setSelectedSubscription(subscription);
	};

	const handleStorageSelect = (storage) => {
		setSelectedStorage(storage);
	};

	return (
		<div className="label-component">
			<div className="camera-name-container equal-width">
				<label className="label">Enter Camera Name</label>
				<InputGroup className="input-group">
					<Input className="input-field" />
					<FontAwesomeIcon className="icon" icon={faCamera} />
				</InputGroup>
			</div>
			<div className="subscription-plan-container equal-width">
				<label className="label">Subscription Plan</label>
				<Dropdown
					className="dropdown"
					isOpen={dropdownOpenSubscription}
					toggle={toggleSubscriptionDropdown}
				>
					<DropdownToggle className="dropdown" caret>
						{selectedSubscription}
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem
							className="dropdown-item"
							onClick={() => handleSubscriptionSelect('Business($15/month)')}
						>
							Business($15/month)
						</DropdownItem>
						<DropdownItem
							className="dropdown-item"
							onClick={() =>
								handleSubscriptionSelect('Standard 7 Days Free Trial($8/month)')
							}
						>
							Standard 7 Days Free Trial($8/month)
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
			<div className="video-storage-plan-container equal-width">
				<label className="label">Video Storage Plan</label>
				<Dropdown
					className="dropdown"
					isOpen={dropdownOpenVideoSubscription}
					toggle={toggleVideoSubscriptionDropdown}
				>
					<DropdownToggle className="dropdown" caret>
						{selectedStorage}
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem
							className="dropdown-item"
							onClick={() => handleStorageSelect('Select Video Storage')}
						>
							Select Video Storage
						</DropdownItem>
						<DropdownItem
							className="dropdown-item"
							onClick={() => handleStorageSelect('24 Hours Storage($18/month)')}
						>
							24 Hours Storage($18/month)
						</DropdownItem>
						<DropdownItem
							className="dropdown-item"
							onClick={() => handleStorageSelect('72 Hours Storage($35/month)')}
						>
							72 Hours Storage($35/month)
						</DropdownItem>
						<DropdownItem
							className="dropdown-item"
							onClick={() => handleStorageSelect('1 Week Storage($45/month)')}
						>
							1 Week Storage($45/month)
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
			{cameraIndex > 0 ? (
				<FontAwesomeIcon
					className="icon"
					icon={faTrash}
					onClick={() => removeCamera(cameraIndex)}
					style={{ cursor: 'pointer' }}
				/>
			) : (
				<FontAwesomeIcon
					className="icon"
					icon={faTrash}
					onClick={() => removeCamera(cameraIndex)}
					style={{ visibility: 'hidden' }}
				/>
			)}
		</div>
	);
};
