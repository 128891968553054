import './PlaybackPlayer.css';

import { FiChevronDown, FiChevronRight, FiChevronUp } from 'react-icons/fi';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { generatePresignedUrl, playbackDateTimeApi } from '../../../api/common/CommonApi';
import { useDispatch, useSelector } from "react-redux";

import $ from 'jquery';
import Collapsible from 'react-collapsible';
import CustomLoader from '../../../components/utils/CustomLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import playbackTime from './playbackTime.json';
import { useParams } from 'react-router-dom';

const PlaybackPlayer = () => {

	// const { customer_id, rtsp_channel_id } = useParams();
	// console.log("customer_id ", customer_id, "rtsp_channel_id ", rtsp_channel_id);

	const userLoginData = useSelector(
		(state) => state.userLogin.userLoginResponse
	);
	const [userSubscription, setUserSubscription] = useState(
		userLoginData ? userLoginData.subscription : {}
	);
	const [userLoginDetails, setUserLoginDetails] = useState(
		userLoginData ? userLoginData.user : {}
	);
	const token = userLoginData.token;

	const cameraDetailsData = useSelector(
        (state) => state.cameraDetails
    );
	const [cameraDetails, setCameraDetails] = useState(cameraDetailsData?.camera_response);

	const [showNavigation, setShowNavigation] = useState(false);
	const [loading, setLoading] = useState(false);
	const [presignedUrl, setPresignedUrl] = useState('');
	const videoRef = useRef(null);
	const [openCollapsible, setOpenCollapsible] = useState(null);
	const [openSubCollapsible, setOpenSubCollapsible] = useState(null);
	const [playbackDateTime, setPlaybackDateTime] = useState([]);

	const { addToast } = useToasts();

	useEffect(() => {
		getPlaybackDateTime();
	}, []);

	const getPlaybackDateTime = async () =>{

		const request_data = {
		  "customer_id" : userLoginDetails.customer_id.toString(),
		  "device_id" :  cameraDetails?.device_id,
		}

		console.log("request_data ", request_data);
		setLoading(true);

		try 
		{
				const response = await playbackDateTimeApi(request_data, token);
				setLoading(false);
				if(response.status_code == 200)
				{
					setPlaybackDateTime(response.video_data)
					// addToast(response.message, { appearance: 'success' });
					// navigate("/streamtest");
				}
				else{
					addToast(response.message, { appearance: 'error' });
				}
				
		} 
		catch (error) {
			setLoading(false);
			// Handle error
			if (error.status_code === 404) 
			{
				// Handle 404 Not Found error
			} 
			else if (error.status_code === 500) 
			{
				// Handle 500 Internal Server Error
			} 
			else 
			{
				// Handle other error cases
			}
		}
	}

	const toggleNavigation = () => {
		if (showNavigation) {
			// $(".navigation-drawer").css
			console.log('showNavigation1 ', showNavigation);
			// $('.navigation-drawer').css({
			// 	width: '0px',
			// });
			$('.nav-content').css({
				display: 'none',
			});
		} else {
			console.log('showNavigation2 ', showNavigation);
			// $('.navigation-drawer').css({
			// 	width: '200px',
			// });
			$('.nav-content').css({
				display: 'block',
			});
		}
		if (openCollapsible !== null) {
			setOpenCollapsible(null);
		}
		if (openSubCollapsible !== null) {
			setOpenSubCollapsible(null);
		}
		setShowNavigation((prevShowNavigation) => !prevShowNavigation);
	};

	const getPreSignedUrl = async (storage_date, time_interval) => {
		// const newVideoSrc = 'https://samplelib.com/lib/preview/mp4/sample-5s.mp4';
		// videoRef.current.src = newVideoSrc;
		// videoRef.current.play();

		console.log("storage_date ", storage_date);
		console.log("time_interval ", time_interval);

		let storage_hour_start_time = time_interval.start_time;
		const combinedDateTimeString_start_time = `${storage_date}T${storage_hour_start_time}:00`;
		const combinedDate_start_time = new Date(combinedDateTimeString_start_time);
		const unixTimestamp_start_time = Math.floor(combinedDate_start_time.getTime() / 1000);

		let storage_hour_end_time = time_interval.end_time;
		const combinedDateTimeString_end_time = `${storage_date}T${storage_hour_end_time}:00`;
		const combinedDate_end_time = new Date(combinedDateTimeString_end_time);
		const unixTimestamp_end_time = Math.floor(combinedDate_end_time.getTime() / 1000);

		const request_data = {
		  "customer_id" : userLoginDetails.customer_id,
		  "device_id" :  cameraDetails?.device_id,
		  "start_timestamp" : unixTimestamp_start_time,
		  "end_timestamp" : unixTimestamp_end_time
		}

		// const request_data = {
		//     "customer_id" : "19618971069",
		//     "device_id" : "G31IMNOf0iFQRpkU",
		//     "start_timestamp" : "1691153700",
		//     "end_timestamp" : "1691161560"
		// }

		console.log("request_data ", request_data);
		setLoading(true);

		try {
		        const response = await generatePresignedUrl(request_data);
		        setLoading(false);
		        if(response.status_code == 200)
		        {
		            setPresignedUrl(response.presigned_url);

					let apiResponseUrl = response.presigned_url;

  					if (apiResponseUrl && videoRef.current) {
						videoRef.current.src = apiResponseUrl;

						// Play the video
						videoRef.current.play()
							.then(() => {
							// Video started playing successfully
							})
							.catch((error) => {
							console.error('Error playing video:', error);
							});
					}


		            addToast(response.message, { appearance: 'success' });
		        }
		        else{
		            addToast(response.message, { appearance: 'error' });
		        }

		    } catch (error) {
		        setLoading(false);
		    if (error.status_code === 404) {
		        } else if (error.status_code === 500) {
		        } else {
		        }
		    }
	};

	const iconStyle = {
		fontSize: '20px',
		marginLeft: '5px',
	};

	return (
		<>
			{loading ? <CustomLoader loading={true} /> : null}
			<div className="video-player-container">
				<div
					className={`navigation-drawer ${showNavigation ? 'open' : 'close'}`}
				>
					<div className="nav-toggle-button" onClick={toggleNavigation}>
						{showNavigation ? (
							<FontAwesomeIcon icon={faAnglesLeft} className="nav-icon" />
						) : (
							<FontAwesomeIcon icon={faAnglesRight} className="nav-icon" />
						)}
					</div>
					<div className="nav-content">
						<h3>Video Storage</h3>

						{playbackDateTime.map((item, index) => (
							<Collapsible
								className="collapsible"
								key={index}
								trigger={
									<div>
										<span className="arrow-icon-wrapper">
											{openCollapsible ? (
												<FiChevronUp className="arrow-icon" />
											) : (
												<FiChevronRight className="arrow-icon" />
											)}
										</span>
										{item.storage_date}
									</div>
								}
								triggerOpenedClassName="collapsible-trigger-open"
								triggerClassName="collapsible-trigger"
								contentOuterClassName="collapsible-content-outer"
								contentInnerClassName="collapsible-content-inner"
								open={openCollapsible === index}
								onOpening={() => setOpenCollapsible(index)}
								onClosing={() => {
									setOpenCollapsible(null);
									setOpenSubCollapsible(null);
								}}
							>
								{item.storage_time.map((time, timeIndex) => (
									<Collapsible
										className="hour-collapse"
										key={timeIndex}
										trigger={
											<div>
												<span className="arrow-icon-wrapper">
													{openCollapsible ? (
														<FiChevronUp className="arrow-icon" />
													) : (
														<FiChevronRight className="arrow-icon" />
													)}
												</span>
												{time.hours}
											</div>
										}
										triggerClassName="collapsible-trigger"
										triggerOpenedClassName="collapsible-trigger-open"
										contentOuterClassName="collapsible-content-outer"
										contentInnerClassName="collapsible-content-inner"
										open={openSubCollapsible === timeIndex}
										onOpening={() => setOpenSubCollapsible(timeIndex)}
										onClosing={() => setOpenSubCollapsible(null)}
									>
										{/* <p>{time.hours}</p> */}
										{time.minutes_interval.map((interval, intervalIndex) => (
											<p
												key={intervalIndex}
												onClick={() => {
													getPreSignedUrl(item.storage_date, interval);
													toggleNavigation();
												}}
											>
												<span className="arrow-icon-wrapper">
													<FiChevronRight className="arrow-icon" />
												</span>
												{interval.start_time} - {interval.end_time}
											</p>
										))}
									</Collapsible>
								))}
							</Collapsible>
						))}
					</div>
				</div>
				<div className="video-container">
					<video ref={videoRef} controls muted={true} autoPlay>
						<source src={presignedUrl} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</>
	);
};

export default PlaybackPlayer;
