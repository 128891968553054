// Login.js
// import '../register/RegisterStep1.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { checkEmailExists, userLoginApi } from '../../api/common/CommonApi';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import CustomLoader from '../../components/utils/CustomLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { setUserLoginResponse } from '../../redux/slices/userLoginSlice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
   const { addToast } = useToasts();
  const transitionRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
 const userLogin = useSelector((state) => state.userLogin);

  

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      'Invalid email address'
    ).required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const onSubmit = async (values) => {

    console.log("onsubmit")

    setLoading(true);
    let request_data = {
        "email":values.email,
        "password":values.password
    }

    console.log("request_data ", request_data);

    try {
            const response = await userLoginApi(request_data);
            setLoading(false);

            if(response.status == true)
            {
                dispatch(setUserLoginResponse({userLoginResponse:response}));
                addToast(response.message, { appearance: 'success' });
                navigate('/dashboard');
            }
            else
            {
                addToast(response.message, { appearance: 'error' });
            }
            
        } catch (error) {
            console.log(" error ", error)
            setLoading(false);
            // Handle error
            if (error.status_code === 404) {
                // Handle 404 Not Found error
                } else if (error.status_code === 500) {
                // Handle 500 Internal Server Error
                } else {
                // Handle other error cases
            }
        }

  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {

    console.log("userLogin ", userLogin)
    if(userLogin && Object.keys(userLogin.userLoginResponse).length !== 0 && userLogin.userLoginResponse.user.email){
        navigate("/dashboard");
    }

  }, []);



  return (
    <>
        {loading ? (
                    <CustomLoader loading={true}/>
                
                ) : null}


    <div className="bg-light login-main">
        <div className="login-logo">
          <img src="assets/img/logo_black.png" className="img-fluid" alt="logo_black" title />
        </div>
        <div className="login-formbox">
          <h2 className="text-center mb-4">Login</h2>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ values, setFieldValue }) => (
                <>
                    <Form className='register-step1-form'>
                    {/* Email input */}
                    
                    <div className="form-outline mb-4">
                        <Field type="email" name="email" id="form2Example1" className="form-control" placeholder="Email address" />
                        <ErrorMessage name="email" component="div" className="text-danger" />
                    </div>

                    {/* Password input */}
                    <div className="form-outline mb-4">
                        <div className="input-group">
                                        <Field
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        className="form-control"
                                        placeholder="Password"
                                        />
                                        <div className="input-group-append" onClick={togglePasswordVisibility}>
                                        <span className="input-group-text show-password-icon">
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </span>
                                        </div>
                                    </div>
                                    <ErrorMessage name="password" component="div" className="text-danger" />
                    </div>
                    
                    {/* 2 column grid layout for inline styling */}
                    {/* <div className="row mb-4">
                        <div className="col d-flex justify-content-center">
                            <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultValue id="form2Example31" defaultChecked />
                            <label className="form-check-label" htmlFor="form2Example31"> Remember me </label>
                            </div>
                        </div>
                        <div className="col justify-content-end">
                            <a href="#!">Forgot password?</a>
                        </div>
                    </div> */}

                    {/* Submit button */}
                    <div className="text-center">
                        <button type="button" className="btn btn-secondary btn-block mb-4" style={{marginRight:"5px"}}>Cancel</button>
                        <button type="submit" className="btn btn-primary btn-block mb-4">Sign in</button>
                    </div>

                    {/* Register buttons */}
                    <div className="text-center">
                        <p>Not a member? <Link to="/register-step1">Register</Link></p>
                    </div>
                    </Form>

                </>
                )}       
            </Formik>
          
        </div>
        <div>
          <small>Copyright © 2023 IPCamStream</small>
        </div>
      </div>



    </>
    
  );
};

export default Login;
