import './Dashboard.css'

import FixedHeaderDashboard from '../../components/fixed-header-dashboard/FixedHeaderDashboard';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {

  const navigate = useNavigate();

  const addNewCamera = () =>{
    navigate("/streamtest");
  }
  
  return (
    <div>
      <FixedHeaderDashboard componentName="dashboard"/>
      <div>
        <div className="wrap--gray-lightest">
            <div className="dashboard-page-title">Cameras</div>
        </div>

        <div className="dashboard-container">
          <div className='new-camera-container'>
            <button className="btn btn-success my-component-button" onClick={()=>{addNewCamera()}}>
              <i className="fa fa-plus"></i> New camera
            </button>
          </div>
        </div>

      </div>
     

      
      {/* Add more content or components for your dashboard */}
    </div>
  );
};

export default Dashboard;
