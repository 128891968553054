import './FixedHeaderDashboard.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetUserLogin } from '../../redux/slices/userLoginSlice';

const FixedHeaderDashboard = (props) => {

  const userLoginData = useSelector(
    (state) => state.userLogin.userLoginResponse
  );
  const [userSubscription, setUserSubscription] = useState(
    userLoginData ? userLoginData.subscription : {}
  );
  const [userLoginDetails, setUserLoginDetails] = useState(
    userLoginData ? userLoginData.user : {}
  );
  const token = userLoginData.token;

    const { componentName } = props;


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fullName, setFullName] = useState("");

  useEffect(() => {

    let fullName = "";

    if (userLoginDetails.first_name) {
      fullName += userLoginDetails.first_name;
    }

    if (userLoginDetails.last_name) {
      if (fullName) {
        fullName += " ";
      }
      fullName += userLoginDetails.last_name;
    }

    setFullName(fullName);
    

  }, [])
  

  const logout = () =>{
    // console.log("logout");
    // navigate("/login");
    dispatch(resetUserLogin());
    navigate('/login');
    
    
  }

  return (
    <div className="wrap wrap--black wrap--header--loggedin" style={{ zIndex: 255 }}>
      <header className="header-dashboard">
        <div className="header__logo">
          <a href="/" className="logo">
            <img src="assets/images/logo.png" className="logo__image" alt="Logo" />
          </a>
        </div>

        <div className="header__nav">
          <button className="hamburger hamburger--slider js-navbar-toggle" type="button">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <ul className="navbar navbar--mobile js-navbar">
            <li className={componentName == "dashboard"? "navbar__item navbar__item--is-active" : ""}>
              <a className="navbar__item__link" href="/dashboard">
                <svg xmlns="http://www.w3.org/2000/svg" className="navbar__item__icon">
                  <use xlinkHref="/tpls2/img/icons.svg#dashboard"></use>
                </svg>
                <span>Dashboard</span>
              </a>
            </li>

            <ul className="navbar__item">
              <li className={componentName == "cameras"? "navbar__item navbar__item--is-active" : ""}>
                <a className="navbar__item__link" href="/cameras">
                  <svg xmlns="http://www.w3.org/2000/svg" className="navbar__item__icon">
                    <use xlinkHref="/tpls2/img/icons.svg#camera"></use>
                  </svg>
                  <span>Cameras</span>
                </a>
              </li>
            </ul>

            {/* <li className="navbar__item">
              <a className="navbar__item__link" href="/accountstatus">
                <svg xmlns="http://www.w3.org/2000/svg" className="navbar__item__icon">
                  <use xlinkHref="/tpls2/img/icons.svg#account"></use>
                </svg>
                <span>Account</span>
              </a>
            </li> */}

            <li className="navbar__item navbar__item--avatar">
              <a className="navbar__item__link" data-toggle="dropdown" aria-expanded="false">
                <img src="https://randomuser.me/api/portraits/lego/7.jpg" className="navbar__avatar" alt="User Avatar" />
                <span style={{marginLeft:"10px"}}>{fullName}</span>
              </a>
              <ul className="navbar-submenu dropdown-menu">
                <li className="navbar-submenu__item">
                  <a className="navbar-submenu__item__link" href="/profile">
                    Profile
                  </a>
                </li>
                {/* <li className="navbar-submenu__item">
                  <a className="navbar-submenu__item__link" href="/changepassword">
                    Change password
                  </a>
                </li> */}
                <li className="navbar-submenu__item">
                  <a className="navbar-submenu__item__link" href="/subscription-create">
                    Add New Device
                  </a>
                </li>
                <li className="navbar-submenu__item">
                  <a className="navbar-submenu__item__link" href="/subscription-cancel">
                    Subscription Cancel
                  </a>
                </li>
                <li className="navbar-submenu__item">
                  <a className="navbar-submenu__item__link" onClick={()=>{logout()}}>
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default FixedHeaderDashboard;
