import { createSlice } from '@reduxjs/toolkit';

// Define your initial state
const initialState = {
  count: 0,
  name: '',
};

// Create a slice with reducers and actions
const counterSliceData = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment(state) {
      state.count++;
    },
    decrement(state) {
      state.count--;
    },
    setName(state, action) {
      state.name = action.payload;
    },
  },
});

// Export the reducer from the slice
export const { increment, decrement, setName } = counterSliceData.actions;

export default counterSliceData;