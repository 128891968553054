import './../profile/Profile.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
	getCountries,
	getStates,
	userRegister,
} from '../../api/common/CommonApi';
import { useEffect, useState } from 'react';

import ChangePassword from './change-password/ChangePassword';
import FixedHeaderDashboard from '../../components/fixed-header-dashboard/FixedHeaderDashboard';
import PersonalInformation from './personal-information/PersonalInformation';
import React from 'react';
import { useSelector } from 'react-redux';

const Profile = () => {
	const [activeTab, setActiveTab] = useState('personalInfo'); // Set default active tabconst userRegisterData = useSelector((state) => state.userRegister);

	const [emailExistResponse, setEmailExistResponse] = useState({});
	const userRegisterData = useSelector((state) => state.userRegister);
	const [countriesList, setCountriesList] = useState([]);
	const [statesList, setStatesList] = useState([]);
	const [isUnitedStateSelected, setIsUnitedStateSelected] = useState(
		userRegisterData.isUnitedStateSelected
	);

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	return (
		<div>
			<FixedHeaderDashboard componentName="dashboard" />
			<div className="update-profile-wrapper new-container">
				<div className="title ">
					<h1>My Profile</h1>
					<p>Manage your personal information</p>
				</div>
				<div className="tab-container">
					<div className="tab-buttons">
						<button
							className={`tab-button ${
								activeTab === 'personalInfo' ? 'active' : ''
							}`}
							onClick={() => handleTabChange('personalInfo')}
						>
							Personal Information
						</button>
						<button
							className={`tab-button ${
								activeTab === 'changePassword' ? 'active' : ''
							}`}
							onClick={() => handleTabChange('changePassword')}
						>
							Change Password
						</button>
					</div>
				</div>
				<div className="tab-content">
					{activeTab === 'personalInfo' ? (
						<>
							<PersonalInformation />
						</>
					) : (
						<>
							<ChangePassword />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Profile;
