// RegisterStep1.js
import './RegisterStep1.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import FixedHeader from '../../components/fixed-header/FixedHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RegisterStep2 from './RegisterStep2';
import { checkEmailExists } from '../../api/common/CommonApi';
import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import { setUserRegisterDataStep1 } from '../../redux/slices/userRegisterSlice';

const RegisterStep1 = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { addToast } = useToasts();
	const transitionRef = useRef(null);
	const userRegisterData = useSelector((state) => state.userRegister);

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [emailExistResponse, setEmailExistResponse] = useState({});

	const initialValues = {
		first_name: userRegisterData.first_name,
		last_name: userRegisterData.last_name,
		email: userRegisterData.email,
		phone: userRegisterData.phone,
		password: userRegisterData.password,
		confirmPassword: userRegisterData.confirmPassword,
	};

	const validationSchema = Yup.object({
		first_name: Yup.string().required('First Name is required'),
		last_name: Yup.string().required('Last Name is required'),
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		phone: Yup.string(),
		password: Yup.string()
			.required('Password is required')
			.min(6, 'Password must be at least 6 characters'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Confirm Password is required'),
	});

	const onSubmit = (values) => {
		if (emailExistResponse.status == true) {
			addToast(emailExistResponse.message, { appearance: 'info' });
			return;
		}

		const userRegisterData = {
			first_name: values.first_name,
			last_name: values.last_name,
			email: values.email,
			phone: values.phone,
			password: values.password,
			confirmPassword: values.confirmPassword,
		};

		dispatch(setUserRegisterDataStep1(userRegisterData));
		navigate('/register-step2');
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	useEffect(() => {
		initialValues.first_name = userRegisterData.first_name;
		initialValues.last_name = userRegisterData.last_name;
		initialValues.email = userRegisterData.email;
		initialValues.phone = userRegisterData.phone;

		initialValues.password = userRegisterData.password;
		initialValues.confirmPassword = userRegisterData.confirmPassword;
	}, []);

	const checkEmailExistsApi = async (email) => {
		let request_data = {
			email: email,
		};

		try {
			const response = await checkEmailExists(request_data);

			if (response.status_code == 200) {
				setEmailExistResponse(response);
				addToast(response.message, { appearance: 'info' });
			}
		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	return (
		<>
			<div>
				<FixedHeader />
				<section className="inner-page-title">
					<div className="container">
						<div className="row">
							<h1 className="text-center text-white">Register</h1>
						</div>
					</div>
				</section>
				<section>
					<div className="container">
						<div className="row">
							<h2 className="text-primary text-center mb-5">
								Create your account
							</h2>
						</div>

						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>
							{({ values, setFieldValue }) => (
								<>
									<Form>
										<div className="row register-tab">
											{/* <ul className="nav nav-tabs" id="myTab" role="tablist">
												<li className="nav-item" role="presentation">
													<button className="nav-link active">
														<i className="fa fa-user" />
														<br />
														PERSONAL &amp;
														<br />
														LOGIN DETAILS
													</button>
												</li>
												<div className="progress-line" />
												<li className="nav-item" role="presentation">
													<button type="submit" className="nav-link">
														<i className="fa fa-credit-card" />
														<br />
														SERVICES &amp;
														<br />
														PAYMENT INFORMATION
													</button>
												</li>
											</ul> */}
											<section class="step-indicator">
												<div class="step step1 active">
													<div class="step-icon">
														<i className="fa fa-user" />
													</div>
													<p>PERSONAL &amp; LOGIN DETAILS</p>
												</div>
												<div class="indicator-line active"></div>
												<div
													class="step step2"
												>
                        <button type="submit" style={{padding: "0px",border: "none",background: "none"}}>
                          <div class="step-icon">
														<i className="fa fa-credit-card" />
													</div>
													<p>
														SERVICES &amp;
														<br />
														PAYMENT INFORMATION
													</p>
                        </button>
													
												</div>
											</section>

											<div className="tab-content" id="myTabContent">
												<div
													className="tab-pane fade show active"
													id="home"
													role="tabpanel"
													aria-labelledby="home-tab"
												>
													<h4 className="text-center mb-3">Personal Details</h4>
													<div className="form-main row">
														<div className="col-md-6">
															<label>
																First Name <sup>*</sup>
															</label>
															<Field
																type="text"
																name="first_name"
																className="form-control"
															/>
															<span className="form-icon">
																<i className="fa fa-user" />
															</span>
															<ErrorMessage
																name="first_name"
																component="div"
																className="text-danger"
															/>
														</div>

														<div className="col-md-6">
															<label>
																Last Name <sup>*</sup>
															</label>
															<Field
																type="text"
																name="last_name"
																className="form-control"
															/>
															<span className="form-icon">
																<i className="fa fa-user" />
															</span>
															<ErrorMessage
																name="last_name"
																component="div"
																className="text-danger"
															/>
														</div>
													</div>
													<div className="form-main row">
														<div className="col-md-6">
															<label>
																EMAIL <sup>*</sup>
															</label>
															<Field
																type="email"
																name="email"
																className="form-control"
																onBlur={() => checkEmailExistsApi(values.email)}
															/>
															<span className="form-icon">
																<i className="fa fa-envelope" />
															</span>
															<ErrorMessage
																name="email"
																component="div"
																className="text-danger"
															/>
														</div>

														<div className="col-md-6">
															<label>Phone Number</label>
															<Field
																type="text"
																name="phone"
																className="form-control"
															/>
															<span className="form-icon">
																<i className="fa fa-phone" />
															</span>
															<ErrorMessage
																name="phone"
																component="div"
																className="text-danger"
															/>
														</div>
													</div>
													<div className="form-main row">
														<div className="col-md-6">
															<label>
																PASSWORD <sup>*</sup>
															</label>
															<Field
																type={showPassword ? 'text' : 'password'}
																name="password"
																className="form-control"
															/>
															<span
																className="form-icon"
																onClick={togglePasswordVisibility}
															>
																<i
																	className={
																		showPassword
																			? 'fa fa-eye-slash'
																			: 'fa fa-eye'
																	}
																/>
															</span>
															<ErrorMessage
																name="password"
																component="div"
																className="text-danger"
															/>
														</div>
														<div className="col-md-6">
															<label>
																CONFIRM PASSWORD<sup>*</sup>
															</label>
															<Field
																type={showConfirmPassword ? 'text' : 'password'}
																name="confirmPassword"
																className="form-control"
															/>
															<span
																className="form-icon"
																onClick={toggleConfirmPasswordVisibility}
															>
																<i
																	className={
																		showConfirmPassword
																			? 'fa fa-eye-slash'
																			: 'fa fa-eye'
																	}
																/>
															</span>
															<ErrorMessage
																name="confirmPassword"
																component="div"
																className="text-danger"
															/>
														</div>
													</div>
													<div className="text-center">
														<button
															type="submit"
															className="btn btn-lg btn-primary"
														>
															Submit &amp; Next
														</button>
													</div>
												</div>
											</div>
										</div>
									</Form>
								</>
							)}
						</Formik>

						<div className="text-center">
							Already have an account? <Link to="/login">Log in</Link>
						</div>
					</div>
				</section>
				<footer>
					Copyright © 2023 IPCamStream
					<footer></footer>
				</footer>
			</div>
		</>
	);
};

export default RegisterStep1;
