import React, { useEffect, useState } from 'react'
import { deleteDevice, updateDevice } from '../../../../api/common/CommonApi';
import { useDispatch, useSelector } from "react-redux";

import CustomLoader from '../../../../components/utils/CustomLoader';
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

function CameraDelete() {

    const cameraDetailsData = useSelector(
        (state) => state.cameraDetails
    );

    const userLoginData = useSelector(
        (state) => state.userLogin.userLoginResponse
    );

    const token = userLoginData.token;


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [cameraDetails, setCameraDetails] = useState(cameraDetailsData?.camera_response);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [])

    const deleteCamera = async () =>{
        console.log("delete camera");

        const device_primary_id = cameraDetails?.id;
        setLoading(true);
        try 
        {
            const response = await deleteDevice(device_primary_id, token);
            console.log("response update_camera ", response);
            setLoading(false);
            if(response.status_code == 200)
            {
                addToast(response.message, { appearance: 'success' });
                navigate("/cameras");
            }
            else if(response.status_code == 400){
                console.log("response", response.message);
                addToast(response.message, { appearance: 'error' });
            }
            // setStatesList(checkedDiscount.states);
        } 
        catch (error) 
        {
        setLoading(true);
            // Handle error
        if (error.status_code === 404) {
            // Handle 404 Not Found error
            } else if (error.status_code === 500) {
            // Handle 500 Internal Server Error
            } else {
            // Handle other error cases
            }
        }
    }


  return (
    <div>
        {loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}
        
        <div style={{textAlign: 'center', fontWeight: 300, color: '#7e7e7e', padding:"40px 0px", fontSize: "38px"}}>Delete Camera</div>

        <div style={{textAlign: 'center', fontWeight: 300,  padding: '30px 0', fontSize: '20px' }}>Once you delete your camera, there is no going back. Please be certain.</div>
        <div
                          style={{ display: "flex", justifyContent: "center" }}
                          className="orange-btn"
                          >
                            <button type="button" className="btn btn-primary" onClick={()=>{deleteCamera()}}>
                          <i
                              className="fa fa-trash mr-4"
                              style={{ marginRight: "10px" }}
                          ></i>{" "}
                          Delete
                          </button>
                          </div>

    </div>
  )
}

export default CameraDelete