import '../Profile.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {
	getCountries,
	getStates,
	updateUserApi,
	userRegister,
} from '../../../api/common/CommonApi';
import { useEffect, useState } from 'react';

import CustomLoader from '../../../components/utils/CustomLoader';
import React from 'react';
import { useSelector } from 'react-redux';

const PersonalInformation = () => {
    const userLoginData = useSelector((state) => state.userLogin.userLoginResponse);
    const [userSubscription, setUserSubscription] = useState(userLoginData ? userLoginData.subscription : {});
    const [userLoginDetails, setUserLoginDetails] = useState(userLoginData ? userLoginData.user : {});
    const token = userLoginData.token;

	const [loading, setLoading] = useState(false);
	const { addToast } = useToasts();
    
	const userRegisterData = useSelector((state) => state.userRegister);
	const [countriesList, setCountriesList] = useState([]);
	const [statesList, setStatesList] = useState([]);
	const [isUnitedStateSelected, setIsUnitedStateSelected] = useState(
		userRegisterData.isUnitedStateSelected
	);
	const validationSchema = Yup.object({
		first_name: Yup.string().required('First Name is required'),
		last_name: Yup.string().required('Last Name is required'),
		phone: Yup.string(),
		country: Yup.string().required('Country is required'),
		state: Yup.string().required('State is required'),
		address: Yup.string().required('Address is required'),
		city: Yup.string().required('City is required'),
		zip: Yup.string().required('Zip is required'),
	});
	const initialValuesPersonalInfo = {
		first_name: userLoginDetails.first_name,
		last_name: userLoginDetails.last_name,
		phone: userLoginDetails.phone,
		country: "",
		state: "",
		address: userLoginDetails.address,
		city: userLoginDetails.city,
		zip: userLoginDetails.zip,
	};
	const handleCountryChange = (event, setFieldValue) => {
		const selectedValue = event.target.value;
		console.log('Selected Country:', selectedValue);

		setFieldValue('country', selectedValue);

		if (selectedValue == 250) {
			setIsUnitedStateSelected(true);
		} else {
			setIsUnitedStateSelected(false);
		}
	};

	const handleStateChange = (event, setFieldValue) => {
		const selectedValue = event.target.value;
		setFieldValue('state', selectedValue);
	};
	const fetchCountries = async () => {
		try {
			const fetchedCountries = await getCountries();
			setCountriesList(fetchedCountries.countries);

			// set the value in form
			const country = fetchedCountries.countries.find((country)=>{
				if(country.country_name == userLoginDetails.country){
					return country
				}
			})

			console.log("country id ", country);
			const country_id = country.id;
			initialValuesPersonalInfo.country = country_id;

			if (country_id == 250) {
				setIsUnitedStateSelected(true);
			} else {
				setIsUnitedStateSelected(false);
			}

		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	const fetchStates = async () => {
		try {
			const fetchedStates = await getStates();
			setStatesList(fetchedStates.states);

			console.log("userLoginDetails.state ", userLoginDetails.state)
			const state = fetchedStates.states.find((state)=>{
				if(state.state_name == userLoginDetails.state){
					return state
				}
			})

			console.log("state obj ", state);
			

			if(state == undefined){
				
				initialValuesPersonalInfo.state = userLoginDetails.state;
				console.log("initialValuesPersonalInfo ", initialValuesPersonalInfo)
			}
			else{
				const state_id = state.id;
				console.log("state id ", state);
				initialValuesPersonalInfo.state = state_id;
			}

		} catch (error) {
			// Handle error
			if (error.status_code === 404) {
				// Handle 404 Not Found error
			} else if (error.status_code === 500) {
				// Handle 500 Internal Server Error
			} else {
				// Handle other error cases
			}
		}
	};

	useEffect(() => {
		// Define an async function to fetch data
		const fetchData = async () => {

			// Use await to fetch countries and states
			await fetchCountries();
			await fetchStates();
		};

		// Call the async function immediately
		fetchData();
	}, []);

	
	const onSubmit = async (values) => {

		const country = countriesList.find((country)=>{
			if(country.id == values.country){
				return country
			}
		})

		console.log("country_name ", country.country_name);
		const country_name = country.country_name;

		const request_data = {
			first_name: values.first_name,
			last_name: values.last_name,
			email: userLoginDetails.email,
			phone: values.phone,
			country: country_name,
			state: values.state,
			address: values.address,
			city: values.city,
			zip: values.zip,
		};
		console.log(request_data);

		setLoading(true);
		
		try {
            const response = await updateUserApi(request_data, token);
            setLoading(false);
            if(response.status_code == 200)
            {
                addToast(response.message, { appearance: 'success' });
            }
            else{
                addToast(response.message, { appearance: 'error' });
            }
            
        } catch (error) {
            setLoading(false);
        // Handle error
        if (error.status_code === 404) {
            // Handle 404 Not Found error
            } else if (error.status_code === 500) {
            // Handle 500 Internal Server Error
            } else {
            // Handle other error cases
            }
        }

		
	};
	return (
		<div>
			
			{loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}

			<div className="personal-inforamtion-tab">
				<Formik
					initialValues={initialValuesPersonalInfo}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({ values, setFieldValue }) => (
						<>
							<Form key={1}>
								<div className="row register-tab">
									<div className="tab-content" id="myTabContent">
										<div
											className="tab-pane fade show active"
											id="home"
											role="tabpanel"
											aria-labelledby="home-tab"
										>
											<div className="form-main row ">
												<div className="col-md-6">
													<label>
														First Name <sup>*</sup>
													</label>
													<Field
														type="text"
														name="first_name"
														className="form-control"
													/>
													<span className="form-icon">
														<i className="fa fa-user" />
													</span>
													<ErrorMessage
														name="first_name"
														component="div"
														className="text-danger"
													/>
												</div>

												<div className="col-md-6">
													<label>
														Last Name <sup>*</sup>
													</label>
													<Field
														type="text"
														name="last_name"
														className="form-control"
													/>
													<span className="form-icon">
														<i className="fa fa-user" />
													</span>
													<ErrorMessage
														name="last_name"
														component="div"
														className="text-danger"
													/>
												</div>
											</div>
											<div className="form-main row">
												
												<div className="col-md-6">
													<label>Phone Number</label>
													<Field
														type="text"
														name="phone"
														className="form-control"
													/>
													<span className="form-icon">
														<i className="fa fa-phone" />
													</span>
													<ErrorMessage
														name="phone"
														component="div"
														className="text-danger"
													/>
												</div>

												<div className="col-md-6">
													<label htmlFor="country">Country</label>
													<Field
														as="select"
														name="country"
														className="form-control"
														onChange={(event) =>
															handleCountryChange(event, setFieldValue)
														}
													>
														<option value="">Select Country</option>

														{countriesList.length > 0
															? countriesList.map((country) => (
																	<option key={country.id} value={country.id}>
																		{country.country_name}
																	</option>
															  ))
															: null}
													</Field>
													<ErrorMessage
														name="country"
														component="div"
														className="text-danger"
													/>
												</div>
											</div>
											<div className="form-main row">
												
												<div className="col-md-6">
													<label>State</label>
													{isUnitedStateSelected ? (
														<>
															<Field
																as="select"
																name="state"
																className="form-control"
																onChange={(event) =>
																	handleStateChange(event, setFieldValue)
																}
															>
																<option value="">Select State</option>

																{statesList.length > 0
																	? statesList.map((state) => (
																			<option key={state.id} value={state.id}>
																				{state.state_name}
																			</option>
																	  ))
																	: null}
															</Field>
														</>
													) : (
														<>
															<Field
																type="text"
																name="state"
																className="form-control"
															/>
															<span className="form-icon">
																<i className="fa fa-map-marker" />
															</span>
														</>
													)}
													<ErrorMessage
														name="state"
														component="div"
														className="text-danger"
													/>
												</div>

												<div className="col-md-6">
													<label htmlFor="address">Address</label>
													<Field
														type="text"
														name="address"
														className="form-control"
													/>
													<span className="form-icon">
														<i className="fa fa-map-marker" />
													</span>
													<ErrorMessage
														name="address"
														component="div"
														className="text-danger"
													/>
												</div>
												
											</div>
											<div className="form-main row">
												

												<div className="col-md-6">
													<label htmlFor="city">City</label>
													<Field
														type="text"
														name="city"
														className="form-control"
													/>
													<span className="form-icon">
														<i className="fa fa-map-marker" />
													</span>
													<ErrorMessage
														name="city"
														component="div"
														className="text-danger"
													/>
												</div>
												<div className="col-md-6">
													<label htmlFor="zip">Zip Code</label>
													<Field
														type="text"
														name="zip"
														className="form-control"
													/>
													<span className="form-icon">
														<i className="fa fa-map-marker" />
													</span>
													<ErrorMessage
														name="zip"
														component="div"
														className="text-danger"
													/>
												</div>

											</div>


											<div className="text-center">
												<button
													type="submit"
													className="btn btn-lg btn-primary"
												>
													Update
												</button>
											</div>
										</div>
									</div>
								</div>
							</Form>
						</>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default PersonalInformation;
