// cameraDetailSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    camera_response: {},
    camera_tab_name: 'Live',
};

const cameraDetailSlice = createSlice({
  name: 'userLogin',
  initialState: initialState,
  reducers: {
    setCameraDetails: (state, action) => {
      const { camera_response, camera_tab_name } = action.payload;
      state.camera_response = camera_response;
      state.camera_tab_name = camera_tab_name;
    },
    resetCameraDetails: (state) => {
      return initialState;
    },
  },
});

export const { setCameraDetails, resetCameraDetails } = cameraDetailSlice.actions;

export default cameraDetailSlice.reducer;
