import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CameraDetails from './views/dashboard/cameras/camera-details/CameraDetails';
import Cameras from './views/dashboard/cameras/Cameras';
import ContactUs from './views/contactus/ContactUs';
import Counter from './views/Counter';
import Dashboard from './views/dashboard/Dashboard';
import Homepage from './views/Homepage';
import LivePlayer from './views/dashboard/liveplayer/LivePlayer';
import Login from './views/login/Login';
import PlaybackPlayer from './views/dashboard/playbackplayer/PlaybackPlayer';
import Pricing from './views/Pricing';
import Profile from './views/profile/Profile';
import React from 'react';
import RegisterStep1 from './views/register/RegisterStep1';
import RegisterStep2 from './views/register/RegisterStep2';
import SharingCamera from './views/dashboard/cameras/sharing/SharingCamera';
import StreamTest from './views/dashboard/streamtest/StreamTest';
import SubscriptionCancel from './views/subscription-cancel/SubscriptionCancel';
import SubscriptionCreate from './views/subscription-create/SubscriptionCreate';
import Terms from './views/terms/Terms';
import TestStreamStorage from './views/TestStreamStorage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/counter"  element={<Counter/>} />
        <Route path="/register-step1"  element={<RegisterStep1/>} />
        <Route path="/register-step2"  element={<RegisterStep2/>} />
        <Route path="/pricing"  element={<Pricing/>} />
        <Route path="/terms"  element={<Terms/>} />
        <Route path="/login"  element={<Login/>} />
        <Route path="/streamtest"  element={<StreamTest/>} />
        
        <Route path="/streams/play/:customer_id/:rtsp_channel_id"  element={<LivePlayer/>} />
        <Route path="/streams/play/storage/:customer_id/:rtsp_channel_id"  element={<LivePlayer/>} />
        <Route path="/test-storage" element={<TestStreamStorage />} />
        
        <Route path="/cameras"  element={<Cameras/>} />
        <Route path="/cameras/details" element={<CameraDetails />} />

        <Route path="/contact" element={<ContactUs />} />
        <Route path="/subscription-cancel" element={<SubscriptionCancel />} />
        <Route path="/video-storage" element={<PlaybackPlayer />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/subscription-create" element={<SubscriptionCreate />} />
        {/* <Route path="/cameras/sharing" element={<SharingCamera />} /> */}
        {/* Add more routes for your application */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
