import React, { useEffect, useState } from 'react'
import { deviceList, enableDevice } from '../../../../api/common/CommonApi';
import { useDispatch, useSelector } from "react-redux";

import CameraDetailsTab from '../camera-details-tab/CameraDetailsTab';
import CustomLoader from '../../../../components/utils/CustomLoader';
import FixedHeaderDashboard from '../../../../components/fixed-header-dashboard/FixedHeaderDashboard';
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

function CameraDetails() {

  const userLoginData = useSelector(
    (state) => state.userLogin.userLoginResponse
  );
  const [userSubscription, setUserSubscription] = useState(
    userLoginData ? userLoginData.subscription : {}
  );
  const cameraDetailsData = useSelector(
    (state) => state.cameraDetails
  );

  const token = userLoginData.token;

    const { addToast } = useToasts();
    const navigate = useNavigate();


  const [loading, setLoading] = useState(false);
  const [devicesList, setDevicesList] = useState([])

  useEffect(() => {
    getDevices();
  }, [])

  const getDevices = async () =>{
    console.log("getDevices");
    
    
    let request_data = {
        customer_id : userSubscription[0].customer_id
    }

    console.log("request_data ", request_data);

    setLoading(true);
    try 
    {
        const response = await deviceList(request_data, token);
        console.log("response deviceList ", response);
        setLoading(false);
        if(response.status_code == 200)
        {
            // addToast(response.message, { appearance: 'success' });
            setDevicesList(response.device)
            // navigate("/cameras");
        }
        else if(response.status_code == 400){
            console.log("response", response.message);
            addToast(response.message, { appearance: 'error' });
        }
        // setStatesList(checkedDiscount.states);
    } 
    catch (error) 
    {
      setLoading(true);
        // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
  }

  const addCamera = () =>{
    navigate("/streamtest");
  }
  

  return (
    <div>
        {loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}
        <FixedHeaderDashboard componentName="cameras" />

        <div>
          <div className="wrap--gray-lightest">
            <div className="dashboard-page-title">Cameras</div>
          </div>

          <div className="dashboard-container">
            {/* <h2 className="text-center mt-4 mb-5">Device List</h2> */}
            <CameraDetailsTab />
            

          </div>

        </div>

        
    </div>
  )
}

export default CameraDetails