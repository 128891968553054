import { Link, useNavigate } from 'react-router-dom';

import React from 'react';

const Homepage = (props) => {

  let navigate = useNavigate();

  const goToRegister = () =>{
    navigate("register-step1");
  }
  return (
    <div>
      {/* Header */}
      <section className="header">
        <div className="top-section clearHeader">
          <div className="position-relative container">
            <div className="logo">
              <a href="index.html">
                <img src="assets/images/logo.png" className="img-fluid" alt="Logo" title="" />
              </a>
            </div>
            <div className="login">
              {/* <a className="btn-css margin-0" href="#">Try Camera</a>
              <a className="btn-css2 margin-0" href="#">Login</a> */}
              
              <Link className="btn-css margin-0" to="/register-step1">Try Camera</Link>
              <Link className="btn-css2 margin-0" to="/login">Login</Link>
              <Link className="btn-css2 margin-0" to="/pricing">Pricing</Link>
              {/* <Link className="btn-css2 margin-0" to="/test-storage">Test S3</Link> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="text-white text-center center-text auto-center">
            <h1>Cloud based video broadcasting solution for IP cameras</h1>
            <p>Easiest way to embed live video in your web page!<br />Generate time-lapse video online!</p>
          </div>
          <img src="assets/images/device.png" alt="Device" title="" className="img-fluid device-img" />
        </div>
      </section>

      <div className="clearfix"></div>

      {/* Main Features */}
      <section className="features-section">
        <div className="container">
          <h2 className="page-title text-center">Checvk out Main Features</h2>
          <ul>
            <li>
              <div className="item">
                <img src="assets/images/hd-logo.png" alt="HD Logo" title="" />
              </div>
              <h2>HD Video stream</h2>
              <p>Supports Full HD video resolution for excellent video quality</p>
            </li>
            <li>
              <div className="item">
                <img src="assets/images/viewers.png" alt="Viewers" title="" />
              </div>
              <h2>Unlimited viewers</h2>
              <p>It can serve as many viewers as you have</p>
            </li>
            <li>
              <div className="item">
                <img src="assets/images/RTMP-RTSP.png" alt="RTMP/RTSP" />
              </div>
              <h2>RTMP/RTSP</h2>
              <p>Supports RTSP/RTMP video streaming protocol and can handle h.264/h.265 video compression</p>
            </li>
            <li>
              <div className="item">
                <img src="assets/images/time-lapse-video.png" alt="Time-lapse video" title="" />
              </div>
              <h2>Time-lapse video</h2>
              <p>Generate Time-lapse video clip(s) from the live video stream</p>
            </li>
            <li>
              <div className="item">
                <img src="assets/images/privacy-control.png" alt="Privacy control" title="" />
              </div>
              <h2>Privacy control</h2>
              <p>AI based algorithm detects and blurs the faces to protect privacy</p>
            </li>
            <li>
              <div className="item">
                <img src="assets/images/overlay-panel.png" alt="Overlay panel" title="" />
              </div>
              <h2>Stream domain lock</h2>
              <p>Protects your video stream against embedding on a foreign domain</p>
            </li>
            <li>
              <div className="item">
                <img src="assets/images/overlay-panel.png" alt="Overlay panel" />
              </div>
              <h2>Overlay panel</h2>
              <p>You can display any HTML content on your live stream</p>
            </li>
            <li>
              <div className="item">
                <img src="assets/images/stream-sharing.png" alt="Stream sharing" />
              </div>
              <h2>Stream sharing</h2>
              <p>Easiest way to connect your IP camera to a social media platform (e.g. Facebook or Youtube)</p>
            </li>
          </ul>
        </div>
      </section>

      <div className="clearfix"></div>

      {/* Integration */}
      <section className="integration">
        <div className="container">
          <div className="left-side">
            <img src="assets/images/left-img.png" alt="Integration" title="" className="img-fluid" />
          </div>
          <div className="right-side">
            <h2>Ease of integration</h2>
            <ul>
              <li>You need only one stream from your IP camera to be transferred to IPCamLive server</li>
              <li>Receive the stream directly from the IP camera, so you do not need any additional PC or software to run</li>
              <li>You can easily embed the live video stream in your web page using the HTML snippet</li>
              <li>Servers can serve an unlimited number of clients</li>
              <li>Your video will be displayed on any device regardless of whether it’s a PC, MAC, mobile or tablet</li>
            </ul>
            <a className="btn-css" href="#">Try Camera</a>
          </div>
        </div>
      </section>

      <div className="clearfix"></div>

      {/* Time-lapse Clip */}
      <section className="integration time-lapse bg-white">
        <div className="container">
          <div className="left-side2">
            <h2>Time-lapse Clip</h2>
            <ul>
              <li>All you need is an IP camera, IPCamStream will take care of the rest</li>
              <li>IPCamStream generates both long-lapse and time-lapse clips at the same time. Long time-lapse captures many days, weeks or even years into a couple of minutes long video. Daily time-lapse captures a whole day into a single 2 minute long video video clip.</li>
              <li>IPCamStream hosts your clips so you can easily embed the generated time-lapse videos to your webpage</li>
            </ul>
            <a className="btn-css" href="#">Try Camera</a>
          </div>
          <div className="right-side2">
            <img src="assets/images/mobile.png" alt="Mobile" title="" className="img-fluid" />
          </div>
        </div>
      </section>

      <div className="clearfix"></div>

      {/* What our Customers have to say */}
      <section className="customers">
        <div className="container carousel">
          <h2 className="page-title text-center">What our Customers have to say</h2>
          <div className="owl-carousel owl-theme">
            <div className="item">
              <img src="assets/images/comma.png" className="comma" alt="Quotation Mark" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et odio non mi feugiat accumsan ac non lorem.</p>
              <h6>Roger Moore,</h6>
              <h5>CEO Moore</h5>
            </div>
            <div className="item">
              <img src="assets/images/comma.png" className="comma" alt="Quotation Mark" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et odio non mi feugiat accumsan ac non lorem.</p>
              <h6>Roger Moore,</h6>
              <h5>CEO Moore</h5>
            </div>
            <div className="item">
              <img src="assets/images/comma.png" className="comma" alt="Quotation Mark" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et odio non mi feugiat accumsan ac non lorem.</p>
              <h6>Roger Moore,</h6>
              <h5>CEO Moore</h5>
            </div>
            <div className="item">
              <img src="assets/images/comma.png" className="comma" alt="Quotation Mark" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et odio non mi feugiat accumsan ac non lorem.</p>
              <h6>Roger Moore,</h6>
              <h5>CEO Moore</h5>
            </div>
            <div className="item">
              <img src="assets/images/comma.png" className="comma" alt="Quotation Mark" />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et odio non mi feugiat accumsan ac non lorem.</p>
              <h6>Roger Moore,</h6>
              <h5>CEO Moore</h5>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </section>

      <div className="clearfix"></div>

      {/* Subscribe */}
      <section className="subscribe">
        <div className="container text-center">
          <h2>It’s free to try. <br />7 days Free Trial.</h2>
          <div className="news__form">
            <input type="email" className="input" placeholder="Enter your email address" />
            <button className="news__btn">Send</button>
          </div>
          <p>If you have any questions <Link to="/contact" className="text-orange">contact us.</Link></p>
        </div>
      </section>

      <div className="clearfix"></div>

      {/* Footer */}
      <footer>
        <p>2023 IPCamStream. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Homepage;
