import './Cameras.css'

import React, { useEffect, useState } from 'react'
import { deviceList, enableDevice } from '../../../api/common/CommonApi';
import { useDispatch, useSelector } from "react-redux";

import CustomLoader from '../../../components/utils/CustomLoader'
import FixedFooter from '../../../components/fixed-footer/FixedFooter'
import FixedHeader from '../../../components/fixed-header/FixedHeader'
import FixedHeaderDashboard from '../../../components/fixed-header-dashboard/FixedHeaderDashboard';
import { setCameraDetails } from '../../../redux/slices/cameraDetailSlice';
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

function Cameras() {

  const userLoginData = useSelector(
    (state) => state.userLogin.userLoginResponse
  );
  const [userSubscription, setUserSubscription] = useState(
    userLoginData ? userLoginData.subscription : {}
  );

  const cameraDetailsData = useSelector(
    (state) => state.cameraDetails
  );

  const token = userLoginData.token;

    const { addToast } = useToasts();
    const navigate = useNavigate();
    const dispatch = useDispatch();


  const [loading, setLoading] = useState(false);
  const [devicesList, setDevicesList] = useState([])

  useEffect(() => {
    getDevices();
  }, [])

  const getDevices = async () =>{
    console.log("getDevices");
    
    
    let request_data = {
        customer_id : userSubscription[0].customer_id
    }

    console.log("request_data ", request_data);

    setLoading(true);
    try 
    {
        const response = await deviceList(request_data, token);
        console.log("response deviceList ", response);
        setLoading(false);
        if(response.status_code == 200)
        {
            // addToast(response.message, { appearance: 'success' });
            setDevicesList(response.device)
            navigate("/cameras");
        }
        else if(response.status_code == 400){
            console.log("response", response.message);
            addToast(response.message, { appearance: 'error' });
        }
        // setStatesList(checkedDiscount.states);
    } 
    catch (error) 
    {
      setLoading(true);
        // Handle error
      if (error.status_code === 404) {
        // Handle 404 Not Found error
        } else if (error.status_code === 500) {
        // Handle 500 Internal Server Error
        } else {
        // Handle other error cases
        }
    }
  }

  const addCamera = () =>{
    navigate("/streamtest");
  }

  const goToLive = (device) =>{
    console.log("goToLive ", device);
    dispatch(setCameraDetails({
      camera_response: device,
      camera_tab_name : "Live"
    }));

    navigate("/cameras/details");
  }

  const goToPlaybackPlayer = (device) =>{
    console.log("goToPlaybackPlayer ", device);
    dispatch(setCameraDetails({
      camera_response: device,
      camera_tab_name : "Playback"
    }));

    navigate("/cameras/details");
  }

  const goToCameraEdit = (device) =>{
    console.log("goToLive ", device);
    dispatch(setCameraDetails({
      camera_response: device,
      camera_tab_name : "Settings"
    }));

    navigate("/cameras/details");
  }

  const addNewCamera = () =>{
    navigate("/streamtest");
  }
  

  return (
    <div className='cameras-container'>
        {loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}
        <FixedHeaderDashboard componentName="cameras" />

        <div>
          <div className="wrap--gray-lightest">
            <div className="dashboard-page-title">Cameras</div>
          </div>

          {/* <div className="dashboard-container">
            <div className='row'>
              <div className='col-md-4'>Camera Name</div>
              <div className='col-md-4'>Enabled</div>
              <div className='col-md-4'>Action</div>
            </div>
          </div> */}

          {devicesList && devicesList.length>0 ? (
            <div className="dashboard-container">
            <h2 className="text-center mt-4 mb-5">Device List</h2>
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Thumbnail</th>
                  <th scope="col">Device Name</th>
                  <th scope="col">Enabled</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {devicesList.map((device) => (
                  <tr key={device.id}>
                    <td>
                      <img style={{height:"60px", width:"100px"}} src='assets/images/thumbnail.jpg'/>
                    </td>
                    <td>{device.device_name}</td>
                    <td>{device.is_enabled ? 'Yes' : 'No'}</td>
                    <td>
                     
                      {device.is_enabled ? (
                        <>
                           <div className="row justify-content-center">
                            <div className="col-auto orange-btn">
                              <button className="btn btn-primary mr-2" onClick={()=>{goToLive(device)}}>Live</button>
                            </div>
                            
                            {device.add_on ? (
                              <div className="col-auto white-btn">
                              <button className="btn btn-primary" onClick={()=>{goToPlaybackPlayer(device)}}>Playback</button>
                            </div>
                            ) : null}
                            
                            <div className="col-auto white-btn">
                              <button className="btn btn-primary" onClick={()=>{goToCameraEdit(device)}}>Edit</button>
                            </div>
                          </div>
                        </>
                        
                      ) : (
                        <>
                          <div
                          style={{ display: "flex", justifyContent: "center" }}
                          className="green-btn"
                          >
                            <button type="button" className="btn btn-primary" onClick={()=>{addCamera()}}>
                          <i
                              className="fa fa-plus mr-4"
                              style={{ marginRight: "10px" }}
                          ></i>{" "}
                          Enable
                          </button>
                          </div>
                        </>
                      )}
                          
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          ) : (
            <div className="dashboard-container">
              <div className='new-camera-container'>
                <button className="btn btn-success my-component-button" onClick={()=>{addNewCamera()}}>
                  <i className="fa fa-plus"></i> New camera
                </button>
              </div>
            </div>
          )}

          

          

        </div>

        
    </div>
  )
}

export default Cameras