// import '../static/register/RegisterStep2.css';

import { PuffLoader } from 'react-spinners';
// GlobalLoader.js
import React from 'react';
import { css } from '@emotion/react';

const CustomLoader = ({ loading }) => {
  const loaderCss = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:"rgba(7,10,9,0.4)",
    zIndex: 9999,
  }

  return (

    <div style={loaderCss}>
        <PuffLoader css={css`display: block; margin: 0 auto;`} size={70} color="#fff" loading={loading} />
    </div>
  );
};

export default CustomLoader;
