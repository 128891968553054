import './Terms.css';

import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';

import FixedFooter from '../../components/fixed-footer/FixedFooter';
import FixedHeader from '../../components/fixed-header/FixedHeader';
import { getSubscriptionPlans } from '../../api/common/CommonApi';

const Terms = () => {

  let navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
    const [subscriptionRegisterPlans, setSubscriptionRegisterPlans] = useState([]);

    useEffect(() => {
      getSubscriptionPlansApi();
      
    }, []);

    const getSubscriptionPlansApi = async () =>{

        try {
            const subscription_plans = await getSubscriptionPlans();

            setSubscriptionRegisterPlans(subscription_plans.plans);

        } catch (error) {
        // Handle error
        if (error.status_code === 404) {
            // Handle 404 Not Found error
            } else if (error.status_code === 500) {
            // Handle 500 Internal Server Error
            } else {
            // Handle other error cases
            }
        }
        
    }

  return (
    <>
      <FixedHeader/>
      
      <div className="terms-container">
        <h2>Terms &amp; Conditions</h2>
        <h3>IpCamStream Terms and Conditions</h3>

        <p>
          PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY. BY UTILIZING OUR SITES, YOU HEREBY AGREE TO BE BOUND BY THE TERMS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE FOLLOWING TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITES OR SERVICES. THIS TERMS OF SERVICE AND CONDITIONS ARE EFFECTIVE AS OF {currentDate}.
        </p>

        <h4>TERMS:</h4>
        <p>
          The following Terms of Service Agreement (‘TOS’) is a legally binding agreement that shall govern the relationship with our users and others which may interact or interface with IpCamStream and affiliates or are associated with the use of the IpCamStream website, which includes but not limited to Ipcamstream.com and its services.
        </p>

        <h4>CHARGES:</h4>
        <p>
          <strong>MONTHLY SERVICE:</strong>
          The service recipient agrees to pay IpCamStream, the monthly subscription fee as per the chosen plan per 7 days throughout the duration of this agreement. The account will continue to be billed every 7 days after the initial term until the service recipient notifies IpCamStream to terminate the agreement.
        </p>
        <p>
          <strong>UPFRONT SERVICE:</strong>
          The service recipient agrees to pay Ipcamstream.com in advance for the initial term of the agreement with an equal amount due for any subsequent renewals after the end of that term.
        </p>
        <p>
          <strong>No refund policy -</strong> All sales are final, canceling your subscription does not warrant a refund.
        </p>
        <p>
          When a subscription is canceled, the user will still have access to all of the IpCamStream services until the end of the billing period.
        </p>
        <p>
          The service recipient will be charged on a monthly basis after the initial upfront agreement has been fulfilled unless the service recipient notifies Ipcamstream.com to terminate the agreement.
        </p>

        <h4>PLAN TYPES:</h4>
        <ul>
          <li>{subscriptionRegisterPlans && subscriptionRegisterPlans[2] && subscriptionRegisterPlans[2].subscription_plan_name} ---------------- {subscriptionRegisterPlans && subscriptionRegisterPlans[2] && subscriptionRegisterPlans[2].price} USD</li>
          <li>{subscriptionRegisterPlans && subscriptionRegisterPlans[1] && subscriptionRegisterPlans[1].subscription_plan_name} ---------------- {subscriptionRegisterPlans && subscriptionRegisterPlans[1] && subscriptionRegisterPlans[1].price} USD</li>
        </ul>

        <h4>WARRANTIES AND LIMITATIONS OF LIABILITY:</h4>
        <p>
          <strong>The Warranties and limitations of Liability are as follows:</strong>
        </p>
        <p>
          Ipcamstream.com makes no warranty as to the results that are attained by the service recipient or others from the use of the services and there are no express or implied warranties of merchantability or fitness for a specific purpose or use.
        </p>
        <p>
          Ipcamstream.com will have no responsibility or liability, contingent or otherwise, for any injury or damages, whether caused by the negligence of Ipcamstream.com or any of its employees, arising in connection with the services rendered under this agreement and will not be liable for any lost profits, losses, punitive, incidental or consequential damages or any claim against the service recipient by any other party.
        </p>

        <h4>SCOPE OF SERVICES:</h4>
        <ol>
          <li>
            a) The data included in the services will not be re-circulated, redistributed or published by the service recipient except for internal purposes without the prior written consent of Ipcamstream.com.
          </li>
          <li>
            b) The service recipient acknowledges and agrees that only a maximum of 1 user(s) is authorized to access the online account or to access data transferred from the online account onto the service recipient’s Customer Relationship Management system (CRM system), throughout the Term. The identity of the authorized user is the signatory of this Agreement and may not be changed without prior written consent from Ipcamstream.com.
          </li>
        </ol>
      </div>

      <FixedFooter/>
    </>
  );
};

export default Terms;
