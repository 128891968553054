import '../Profile.css';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {
	changePasswordApi,
	getCountries,
	getStates,
	userRegister,
} from '../../../api/common/CommonApi';
import { useEffect, useState } from 'react';

import CustomLoader from '../../../components/utils/CustomLoader';
import React from 'react';
import { useSelector } from 'react-redux';

const ChangePassword = () => {

	const userLoginData = useSelector((state) => state.userLogin.userLoginResponse);
    const [userSubscription, setUserSubscription] = useState(userLoginData ? userLoginData.subscription : {});
    const [userLoginDetails, setUserLoginDetails] = useState(userLoginData ? userLoginData.user : {});
    const token = userLoginData.token;

	const [loading, setLoading] = useState(false);
	const { addToast } = useToasts();
	
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};
	const validationSchemaPassword = Yup.object({
		password: Yup.string()
			.required('Password is required')
			.min(6, 'Password must be at least 6 characters'),
		confirmPassword: Yup.string()
			// .oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('New Password is required')
			.min(6, 'Password must be at least 6 characters'),
	});

	const initialValuesPassword = {
		password: '',
		confirmPassword: '',
	};
	
	const onSubmit = async (values) => {
		const request_data = {
			email : userLoginDetails.email,
			old_password: values.password,
			password: values.confirmPassword,
		};
		console.log(request_data);

		setLoading(true);
		
		try {
            const response = await changePasswordApi(request_data, token);
            setLoading(false);
            if(response.status_code == 200)
            {
                addToast(response.message, { appearance: 'success' });
            }
            else{
                addToast(response.message, { appearance: 'error' });
            }
            
        } catch (error) {
            setLoading(false);
        // Handle error
        if (error.status_code === 404) {
            // Handle 404 Not Found error
            } else if (error.status_code === 500) {
            // Handle 500 Internal Server Error
            } else {
            // Handle other error cases
            }
        }

		
	};
	return (
		<div>

			{loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}

			<div className="change-password-tab">
				<Formik
					initialValues={initialValuesPassword}
					validationSchema={validationSchemaPassword}
					onSubmit={onSubmit}
				>
					{({ values, setFieldValue }) => (
						<>
							<Form key={2}>
								<div className="row register-tab">
									<div className="tab-content" id="myTabContent">
										<div
											className="tab-pane fade show active"
											id="home"
											role="tabpanel"
											aria-labelledby="home-tab"
										>
											<div className="form-main row">
												<div className="col-md-6">
													<label>
														CURRENT PASSWORD <sup>*</sup>
													</label>
													<Field
														type={showPassword ? 'text' : 'password'}
														name="password"
														className="form-control"
													/>
													<span
														className="form-icon"
														onClick={togglePasswordVisibility}
													>
														<i
															className={
																showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'
															}
														/>
													</span>
													<ErrorMessage
														name="password"
														component="div"
														className="text-danger"
													/>
												</div>
												<div className="col-md-6">
													<label>
														NEW PASSWORD<sup>*</sup>
													</label>
													<Field
														type={showConfirmPassword ? 'text' : 'password'}
														name="confirmPassword"
														className="form-control"
													/>
													<span
														className="form-icon"
														onClick={toggleConfirmPasswordVisibility}
													>
														<i
															className={
																showConfirmPassword
																	? 'fa fa-eye-slash'
																	: 'fa fa-eye'
															}
														/>
													</span>
													<ErrorMessage
														name="confirmPassword"
														component="div"
														className="text-danger"
													/>
												</div>
											</div>
											<div className="text-center">
												<button
													type="submit"
													className="btn btn-lg btn-primary"
												>
													Change Password
												</button>
											</div>
										</div>
									</div>
								</div>
							</Form>
						</>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default ChangePassword;
