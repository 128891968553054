class ApiConfig {
    
   // baseURL = "http://143.198.181.191:8028/";
   baseURL = "https://ipstreamcameraapi.odisoft.in/";
   // baseURL = "https://api.ipcamstream.com/";
   streamBaseUrl = "https://ipcamstreamapi.odisoft.in/"
   // streamBaseUrl = "https://apistream.ipcamstream.com/"

}

export default ApiConfig;