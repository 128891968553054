import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

const TestStreamStorage = () => {
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState('');
  const [deviceId, setDeviceId] = useState('');

  const handleButtonClick = () => {
    // const baseUrl = window.location.origin;
    // const url = `${baseUrl}/streams/play/storage/${customerId}/${deviceId}`;
    // window.location.href = url;

    const baseUrl = window.location.origin;
    const url = `/streams/play/storage/${customerId}/${deviceId}`;
    navigate(url, { replace: true });

  };

  return (
    <div style={{margin:"100px"}}>
      <form>
        <label htmlFor="customerId">Customer ID:</label>
        <input
          type="text"
          id="customerId"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
        />

        <label htmlFor="deviceId">Device ID:</label>
        <input
          type="text"
          id="deviceId"
          value={deviceId}
          onChange={(e) => setDeviceId(e.target.value)}
        />

        <button type="button" onClick={handleButtonClick}>
          Generate URL and Redirect
        </button>
      </form>
    </div>
  );
};

export default TestStreamStorage;
