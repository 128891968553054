import { persistor, store } from './redux/store';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import { ToastProvider } from 'react-toast-notifications';

const App = () => {
  return (
    <ToastProvider autoDismiss={true} autoDismissTimeout={10000}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    </ToastProvider>
    
  );
};

export default App;
