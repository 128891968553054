import React, { useEffect, useRef } from 'react';

import ApiConfig from '../../../api/ApiConfig';
import Hls from 'hls.js'; // Import Hls from the hls.js library
import { useParams } from 'react-router-dom';

const LivePlayer = () => {
  const videoRef = useRef(null);
  const { customer_id, rtsp_channel_id } = useParams();
  const stream_base_url = new ApiConfig().streamBaseUrl;


  useEffect(() => {
    const video = videoRef.current;
    video.style.height = "98vh";
    video.style.width = "100%";

    // const url = window.location.href;
    // const segments = url.split('/');
    // const rtsp_channel_id = segments[segments.length - 1];

    // const { customer_id, rtsp_channel_id } = useParams();
    
    video.style.objectFit = 'cover';
    callStream(rtsp_channel_id, customer_id);

    document.addEventListener("visibilitychange", handleVisibilityChange, false);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange, false);
    };
  }, []);

  function handleVisibilityChange() {
    if (document.hidden) {
      console.log("The page is hidden");
    } else {
      console.log("Visible");
      callStream(rtsp_channel_id, customer_id);
    }
  }

  function callStream(rtsp_channel_id, customer_id) {
    console.log("Calling callStream");

    console.log("customer_id ", customer_id);
    console.log("rtsp_channel_id ", rtsp_channel_id);

    var fullUrl = window.location.href;
      console.log(fullUrl);
      let hls_source_url = "";

      if(fullUrl.includes("streams/play/storage/")) {
        console.log("hi");
        hls_source_url = `${stream_base_url}streams/play/storage/${customer_id}/${rtsp_channel_id}/get_m3u8`;
      } else if (fullUrl.includes("streams/play/")) {
        console.log("hello");
        hls_source_url = `${stream_base_url}streams/play/${customer_id}/${rtsp_channel_id}/get_mp3u8`
      }


    if (Hls.isSupported()) {

      const hls = new Hls();
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(hls_source_url);
      });
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current.addEventListener('canplay', function () {
          videoRef.current.muted = true;
          videoRef.current.play()
            .then(() => {
              console.log('Video playback started');
              videoRef.current.style.objectFit = 'fill';
            })
            .catch((error) => {
              console.error('Error occurred while starting video playback:', error);
            });
        });
      });
      hls.on(Hls.Events.FRAG_BUFFERED, function (data, event) {
        // Handle buffered fragments if needed
      });
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      console.log("HLS not supported");
      videoRef.current.style.objectFit = 'fill';
      videoRef.current.src = hls_source_url;
      videoRef.current.muted = true;
      videoRef.current.playsInline = true;
      videoRef.current.play();
    }
  }

  return <video ref={videoRef}></video>;
};

export default LivePlayer;
