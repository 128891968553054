import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import CustomLoader from '../../../../components/utils/CustomLoader';
import { updateDevice } from '../../../../api/common/CommonApi';
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

function CameraSetting() {

    const cameraDetailsData = useSelector(
        (state) => state.cameraDetails
    );

    const userLoginData = useSelector(
        (state) => state.userLogin.userLoginResponse
    );

    const token = userLoginData.token;


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [cameraDetails, setCameraDetails] = useState(cameraDetailsData?.camera_response);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [])

    // Define validation schema using Yup
    const validationSchema = Yup.object().shape({
        device_name: Yup.string().required('Device Name is required'),
        stream_url: Yup.string().required('RTSP URL is required'),
    });

    // Define initial form values
    const initialValues = {
        device_name: cameraDetails.device_name,
        stream_url: cameraDetails.stream_url,
    };

    // Handle form submission
    const handleSubmit = (values) => {
        // Perform your submit logic here
        console.log(values);
        update_camera(values)
    };

    const update_camera = async (values) =>{
        console.log("update_camera");

        let request_data = values;
        request_data["customer_id"] = cameraDetails["customer_id"];
        const device_primary_id = cameraDetails?.id;

        setLoading(true);
        try 
        {
            const response = await updateDevice(device_primary_id, request_data, token);
            console.log("response update_camera ", response);
            setLoading(false);
            if(response.status_code == 200)
            {
                addToast(response.message, { appearance: 'success' });
            }
            else if(response.status_code == 400){
                console.log("response", response.message);
                addToast(response.message, { appearance: 'error' });
            }
            // setStatesList(checkedDiscount.states);
        } 
        catch (error) 
        {
        setLoading(true);
            // Handle error
        if (error.status_code === 404) {
            // Handle 404 Not Found error
            } else if (error.status_code === 500) {
            // Handle 500 Internal Server Error
            } else {
            // Handle other error cases
            }
        }

    }

    
    return (
        <div>
            {loading ? (
                <CustomLoader loading={true}/>
               
            ) : null}
            <div style={{textAlign: 'center', fontWeight: 300, color: '#7e7e7e', padding:"40px 0px", fontSize: "38px"}}>General Setting</div>
            <div style={{textAlign:"center"}}>
                <div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                        <div className="row mb-3">
                            <label htmlFor="device_name" className="col-sm-2 col-form-label">
                            Device Name
                            </label>
                            <div className="col-md-6 col-sm-10">
                                <Field
                                    type="text"
                                    className="form-control"
                                    id="device_name"
                                    name="device_name"
                                />
                                <ErrorMessage
                                    name="device_name"
                                    component="div"
                                    className="text-danger"
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="stream_url" className="col-sm-2 col-form-label">
                            RTSP URL
                            </label>
                            <div className="col-md-6 col-sm-10">
                                <Field
                                    type="text"
                                    className="form-control"
                                    id="stream_url"
                                    name="stream_url"
                                    disabled={true}
                                />
                                <ErrorMessage
                                    name="stream_url"
                                    component="div"
                                    className="text-danger"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-10 offset-sm-2">
                                <div
                                    style={{ display: "flex", justifyContent: "center" }}
                                    className="green-btn"
                                    >
                                        <button type="submit" className="btn btn-primary">
                                    
                                    Update
                                    </button>
                                </div>
                            </div>
                        </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default CameraSetting