import ApiConfig from '../ApiConfig';

const base_url = new ApiConfig().baseURL;
const stream_base_url = new ApiConfig().streamBaseUrl;



// console.log("base_url ", base_url);

// Fetches the country list from the API
export const getSubscriptionPlans = async () => {
  try {
    const response = await fetch(base_url+'service/subscription/plan');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching subscription list:', error);
    throw error;
  }
};

// Fetches the country list from the API
export const getAddOnPlans = async () => {
  try {
    const response = await fetch(base_url+'service/addon/plan');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching addon list:', error);
    throw error;
  }
};

// Fetches the countries list from the API
export const getCountries = async () => {
  try {
    const response = await fetch(base_url+'service/countries/list');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching countries list:', error);
    throw error;
  }
};

// Fetches the states list from the API
export const getStates = async () => {
  try {
    const response = await fetch(base_url+'service/states/250/list');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching states list:', error);
    throw error;
  }
};

export const checkDiscount = async (request_data) => {
  try {
    const response = await fetch(base_url + 'service/check/discount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error checking discounts:', error);
    throw error;
  }
};

export const userLoginApi = async (request_data) => {

  try {
    const response = await fetch(base_url + 'users/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error login:', error);
    throw error;
  }

};

export const userRegister = async (request_data) => {
  try {
    const response = await fetch(base_url + 'users/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error user register:', error);
    throw error;
  }
};


export const checkEmailExists = async (request_data) => {

  try {
    const response = await fetch(base_url + 'users/email/exits', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error user checkEmailExists:', error);
    throw error;
  }

};

export const startStream = async (request_data) => {
  try {
    const params = new URLSearchParams();
    for (const key in request_data) {
      params.append(key, request_data[key]);
    }

    const response = await fetch(stream_base_url + 'streams/start', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error user startStream:', error);
    throw error;
  }
};

export const enableDevice = async (request_data, device_id_pk, token) => {
  try {
    const response = await fetch(base_url + 'devices/device?id='+device_id_pk, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`,
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error user enableDevice:', error);
    throw error;
  }
};

export const deviceList = async (request_data, token) => {
  try {
    const response = await fetch(base_url + 'devices/getdevice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`,
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error user deviceList:', error);
    throw error;
  }
};

export const updateDevice = async (device_id, request_data, token) => {
  try {
    const response = await fetch(base_url + 'devices/device?id='+device_id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`,
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error user enableDevice:', error);
    throw error;
  }
};

export const deleteDevice = async (device_id, token) => {
  try {
    const response = await fetch(base_url + 'devices/device?id='+device_id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`,
      },
      body: JSON.stringify(),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error user enableDevice:', error);
    throw error;
  }
};

export const contactUs = async (request_data) => {

  try {
    const response = await fetch(base_url + 'users/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error login:', error);
    throw error;
  }

};

export const subscriptionCancelApi = async (request_data) => {

  try {
    const response = await fetch(base_url + 'user/subscription/cancel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error subscription cancel:', error);
    throw error;
  }

};

export const generatePresignedUrl = async (request_data) => {

  try {
    const response = await fetch(base_url + 'service/generate_presigned_url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error generatePresignedUrl cancel:', error);
    throw error;
  }

};

export const changePasswordApi = async (request_data, token) => {

  try {
    const response = await fetch(base_url + 'users/changepassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`,
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error changePassword :', error);
    throw error;
  }

};

export const updateUserApi = async (request_data, token) => {

  try {
    const response = await fetch(base_url + 'users/user/profile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`,
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updateUserApi :', error);
    throw error;
  }

};

export const subscriptionCreateApi = async (request_data) => {

  try {
    const response = await fetch(base_url + 'users/subscription/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error subscription create:', error);
    throw error;
  }

};

export const playbackDateTimeApi = async (request_data, token) => {

  try {
    const response = await fetch(base_url + 'service/playbackdatetime', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${token}`,
      },
      body: JSON.stringify(request_data),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error playbackDateTime create:', error);
    throw error;
  }

};






