import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function FixedHeader() {

  const userLoginData = useSelector(
    (state) => state.userLogin.userLoginResponse
  );
  const [userSubscription, setUserSubscription] = useState(
    userLoginData ? userLoginData.subscription : {}
  );
  const [userLoginDetails, setUserLoginDetails] = useState(
    userLoginData ? userLoginData.user : {}
  );
  const token = userLoginData.token;

  const [email, setEmail] = useState("");

  useEffect(() => {
    
    if(userLoginDetails && userLoginDetails.email){
      setEmail(userLoginDetails.email)
    }

  }, )
  

  return (
    <>
        <section className="header" style={{padding:"0px 0px 0px 0px"}}>
            <div className="top-section darkHeader" style={{position: "absolute"}}>
            <div className="position-relative container">
                <div className="logo">
                <a href="/">
                    <img src="assets/images/logo.png" className="img-fluid" alt="Logo" title="" />
                </a>
                </div>
                <div className="login">
                
                {email ? null : (
                  <Link className="btn-css margin-0" to="/register-step1">Try Camera</Link>
                )}

                {email ? (
                  <Link className="btn-css2 margin-0" to="/dashboard">Dashboard</Link>
                ) : (
                  <Link className="btn-css2 margin-0" to="/login">Login</Link>
                )}


                <Link className="btn-css2 margin-0" to="/pricing">Pricing</Link>
                </div>
            </div>
            </div>
        </section>
        <div className="clearfix"></div>
    </>
  )
}

export default FixedHeader